import { FetchXmlFactory } from "@talxis/client-libraries/dist/utils/fetch-xml";
import { IFormContext } from "../native/Form/interfaces/IFormContext";
import { Grid } from "./Grid";
import { IExtendedXrmGridControl, IXrmGridRelationship, XrmGridType } from "./interfaces";

export class GridContext implements IExtendedXrmGridControl {
    private _grid: Grid;
    constructor(grid: Grid) {
        this._grid = grid;
    }
    getFetchXml(): string {
        return new FetchXmlFactory(this._grid.getDataSource())
            .setLinking(this._grid.linking.getLinkedEntities())
            .setColumns(this._grid.columns)
            .setSorting(this._grid.sorting)
            .setFilter([this._grid.filtering.getFilter()])
            .build();
    }
    getViewColumns(): ComponentFramework.PropertyHelper.DataSetApi.Column[] {
        return this._grid.columns;
    }
    getGridType(): XrmGridType {
        return this._grid.gridType;
    }
    getRelationship(): IXrmGridRelationship {
        return this._grid.relationship;
    }
    getParentForm?(): IFormContext {
        return this._grid.formContext;
    }
    getOutputs() {
        const prefix = `${this._grid.getControl().getControlProps().id}`;
        const outputs = this._grid.getControl().getOutputs();
        const result: any = {};
        Object.entries(outputs).map(([key, value]) => {
            result[`${prefix}.${key}`] = {
                value: value,
                paramType: undefined,
                type: 2
            };
        });
        return result;
    }
    getGrid(): Xrm.Controls.Grid {
        return {
            getSelectedRows: () => {
                return {
                    forEach: (delegate) => {
                        for (const [index, id] of Object.entries(this._grid.getSelectedRecordIds())) {
                            const item: Xrm.Controls.Grid.GridRow = {
                                data: {
                                    entity: {
                                        getId: () => id,
                                        attributes: {
                                            get: (delegate: string | ((attribute: Xrm.Attributes.Attribute, index: number) => boolean)) => {
                                                if (typeof delegate === 'function') {
                                                    const attributes = [];
                                                    const columns = this._grid.columns;
                                                    for (let i = 0; i < columns.length; i++) {
                                                        const attribute = {
                                                            getName: () => columns[i].name,
                                                            getValue: () => this._grid.records[id].getValue(columns[i].name)
                                                        };
                                                        //@ts-ignore - We are not implementing the entire XRM Attributes
                                                        if (delegate(attribute, i)) {
                                                            attributes.push(attribute);
                                                        }
                                                    }
                                                    return attributes;
                                                }
                                                else if (typeof delegate === 'string') {
                                                    const attribute = this._grid.columns.find(column => column.name === delegate);
                                                    if (attribute) {
                                                        return {
                                                            getValue: () => this._grid.records[id].getValue(delegate),
                                                            getName: () => delegate
                                                        };
                                                    } else {
                                                        return null;
                                                    }
                                                } else {
                                                    throw new Error("We do not support other data types!");
                                                }
                                            },
                                            getLength: () => this._grid.columns.length,
                                            forEach: (attributeDelegate) => {
                                                const columns = this._grid.columns;
                                                for (let i = 0; i < columns.length; i++) {
                                                    const attribute = {
                                                        getName: () => columns[i].name,
                                                        getValue: () => this._grid.records[id].getValue(columns[i].name)
                                                    };
                                                    //@ts-ignore - We are not implementing the entire XRM Attributes
                                                    attributeDelegate(attribute, i);
                                                }
                                            }
                                        }
                                    },
                                } as Xrm.Data, // TODO: This shouldn't be any, but Xrm.Data
                                getData: () => { throw new Error("Not implemented!"); },
                            };
                            delegate(item, +index);
                        }
                    },
                    get: () => { throw new Error("Not implemented!"); },
                    getLength: () => this._grid.getSelectedRecordIds().length,
                };
            },
            getRows: () => { throw new Error("Not implemented!"); },
            getTotalRecordCount: () => { throw new Error("Not implemented!"); },
        };
    }
    addOnLoad?(handler: Xrm.Events.ContextSensitiveHandler): void {
        throw new Error("Method not implemented.");
    }
    getContextType?(): XrmEnum.GridControlContext {
        throw new Error("Method not implemented.");
    }
    getEntityName?(): string {
        return this._grid.getTargetEntityType();
    }
    getViewSelector?(): Xrm.Controls.ViewSelector {
        return this._grid.viewSelector;
    }
    refresh?(): void {
        this._grid.refresh();
    }
    refreshRibbon?(): void {
        this._grid.ribbon.refresh();
    }
    removeOnLoad?(handler: () => void): void {
        throw new Error("Method not implemented.");
    }
    getControlType?(): string {
        throw new Error("Method not implemented.");
    }
    getName?(): string {
        return this._grid.getTitle();
    }
    getParent?(): Xrm.Controls.Section {
        throw new Error("Method not implemented.");
    }
    getLabel?(): string {
        throw new Error("Method not implemented.");
    }
    setLabel?(label: string): void {
        throw new Error("Method not implemented.");
    }
    getVisible?(): boolean {
        throw new Error("Method not implemented.");
    }
    setVisible?(visible: boolean): void {
        throw new Error("Method not implemented.");
    }
}