import React, { useContext, useEffect, useState } from 'react';
import { DefaultButton, Label, mergeStyles, Panel, PrimaryButton, useTheme } from '@fluentui/react';
import { formatXrmGuid } from "@src/app/Functions";
import { AppContext } from "@src/providers/AppProvider";
import styles from './LookupPanel.module.css';
import { getLocalizedString } from "@src/localization/helpers";
import { IXrmLookupOptions } from "@src/app/interfaces/IXrmLookupOptions";
import { ILookupProperty, Lookup } from '@talxis/base-controls';
import { ViewDefinition } from '@src/app/classes/definitions/ViewDefinition';
import { ThemeDefinition } from '@src/app/classes/definitions/ThemeDefinition';
import { UserSettingsDefinition } from '@src/app/classes/definitions/UserSettingsDefinition';
import { Formatting } from '@talxis/client-libraries/dist/utils/formatting/Formatting';

export interface ILookupPanelProps extends IXrmLookupOptions {
    isOpen: boolean;
    onSuccessCallback(result: ComponentFramework.LookupValue[]): void
}
export const LookupPanel: React.FC<ILookupPanelProps> = (props) => {
    const appContext = useContext(AppContext);
    const theme = useTheme();
    const [records, setRecords] = useState<ComponentFramework.LookupValue[]>([]);
    const [refresh, setRefresh] = useState<boolean>(false);
    const [entityDisplayName, setEntityDisplayName] = useState<string | null>("");

    const className = `${styles.root} ${mergeStyles({
        '.ms-Panel-footer': {
            borderTop: `1px solid ${theme.semanticColors.bodyDivider}`,
            background: theme.semanticColors.bodyBackground
        }
    })}`;
    const onDismissHandler = () => {
        props.onSuccessCallback([]);
        appContext.setLookupPanelProps({
            ...appContext.lookupPanelProps,
            isOpen: false
        });
        setRecords([]);
    };
    const onConfirmHandler = () => {
        for (const value of records) {
            value.id = formatXrmGuid(value.id).toUpperCase();
        }
        props.onSuccessCallback(records);
        appContext.setLookupPanelProps({
            ...appContext.lookupPanelProps,
            isOpen: false
        });
        setRecords([]);
    };
    useEffect(() => {
        setRefresh(true);
    }, [appContext.lookupPanelProps]);

    useEffect(() => {
        if (refresh) {
            setRefresh(false);
        }
    }, [refresh]);

    useEffect(() => {
        if (!props.entityTypes) {
            return;
        }
        (async () => {
            if (props.entityTypes?.length > 1) {
                setEntityDisplayName(window.TALXIS.Portal.Translations.getLocalizedString('components/navigation/LookupPanel/Record'));
            }
            for (const entityType of props.entityTypes) {
                const metadata = await window.Xrm.Utility.getEntityMetadata(entityType);
                //@ts-ignore - types, we are returning localized string
                setEntityDisplayName(metadata.DisplayName);
            }
        })();
    }, [props.entityTypes]);

    return (
        <Panel {...props}
            className={className}
            headerText={getLocalizedString("components/navigation/panels/LookupPanel/Header")}
            onDismiss={onDismissHandler}
            isFooterAtBottom
            isBlocking
            isLightDismiss={true}
            onOuterClick={() => { }}
            onRenderFooterContent={() => {
                return (
                    <>
                        <PrimaryButton text={getLocalizedString("components/navigation/panels/LookupPanel/Add")} onClick={onConfirmHandler} />
                        <DefaultButton text={getLocalizedString("components/navigation/panels/LookupPanel/Cancel")} onClick={onDismissHandler} />
                    </>
                );
            }}>
            {props.isOpen && !refresh && entityDisplayName &&
                <>
                    <Label>{getLocalizedString("components/navigation/panels/LookupPanel/SubHeader")}</Label>
                    <Lookup context={{
                        //@ts-ignore  TODO: general PCF context class should be created
                        mode: {
                            isControlDisabled: false
                        },
                        //@ts-ignore  TODO: general PCF context class should be created
                        webAPI: window.Xrm.WebApi,
                        //@ts-ignore  TODO: general PCF context class should be created
                        navigation: window.Xrm.Navigation,
                        //@ts-ignore  TODO: general PCF context class should be created
                        utils: window.Xrm.Utility,
                        //@ts-ignore  TODO: general PCF context class should be created
                        userSettings: window.Xrm.Utility.getGlobalContext().userSettings,
                        fluentDesignLanguage: ThemeDefinition.get().controlV9,
                        formatting: Formatting.Get()

                    }}
                        onNotifyOutputChanged={(outputs) => {
                            setRecords(outputs.value);
                        }}
                        parameters={{
                            MultipleEnabled: {
                                raw: true
                            },
                            value: {
                                attributes: {
                                    Targets: props.entityTypes,
                                    DisplayName: entityDisplayName
                                },
                                raw: records,
                                getAllViews: async (entityName: string) => {
                                    const viewDefinition = await ViewDefinition.getLookupViewAsync(entityName);
                                    return [
                                        {
                                            isDefault: true,
                                            fetchXml: viewDefinition.fetchxml,
                                            viewId: viewDefinition.savedqueryid
                                        }
                                    ];
                                }
                            } as ILookupProperty
                        }} />
                </>
            }
        </Panel>
    );
};