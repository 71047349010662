import React, { useEffect, useState, useContext, useRef, useCallback } from 'react';
import Dialog from '../Dialog';
import { rootStyles } from './Styles';
import { IFormDialogProps } from './interfaces';
import { defaultProps } from '../Dialog/Constants';
import { Control } from '../../controls';
import { FormMapper } from '@mappers/FormMapper';
import { AppContext } from '@providers/AppProvider';
import { IAppContext } from '@providers/AppProvider/interfaces';
import Panel from '../../../components/navigation/panels/Panel';
import { FormControlType } from "@controls/native/Form/interfaces/enums";
import './styles.css';
import { DefaultButton, DialogFooter, IButtonProps, mergeStyles, PrimaryButton, useTheme } from '@fluentui/react';
import { Form } from '@controls/native/Form/interfaces/form';
import { executeFunctionByName } from '@src/app/Functions';
import { XrmEventContext, XrmFormContext } from '@src/components/controls/native/Form/Form';
import { Notifications } from '@src/pages/Layout/components/Notifications';
import styles from './FormDialog.module.css';
import { getLocalizedString } from "@src/localization/helpers";
import { FormType } from '@src/app/classes/definitions/FormDefinition';

const FormDialog: React.FC<IFormDialogProps> = (props) => {
    const appContext: IAppContext = useContext(AppContext);
    const [title, setTitle] = useState<string | undefined>("");
    const formDataRef = useRef<ComponentFramework.WebApi.Entity>({});
    const theme = useTheme();
    const executionContext = useRef<XrmEventContext>(null);
    const formContext = useRef<XrmFormContext>(null);
    const [footerButtonCells, setFooterButtonCells] = useState<Form.Cell[]>(null);
    const [shouldRefresh, setShouldRefresh] = useState<boolean>(false);
    const entityId = useRef(props.parameters?.entityId);
    const [disabledNotification, setDisabledNotification] = useState<string>(null);
    const [isNewRecord, setIsNewRecord] = useState<boolean>(false);

    const init = async (formId?: string): Promise<void> => {
        const formDefinition = await (new FormMapper(null, formId, props.formName)).getForm(); // TODO: We don't need to load the whole form, but FormDefinition doesn't give us the title
        setTitle(formDefinition.title);
    };

    // Load the dialog
    useEffect(() => {
        if ((props.formName || props.formId) && !props.customTitle) {
            init(props.formId);
        }
    }, [props.formName, props.formId]);

    useEffect(() => {
        if (shouldRefresh) {
            setShouldRefresh(false);
        }
    }, [shouldRefresh]);

    const onDataChange = useCallback((entity: ComponentFramework.WebApi.Entity) => {
        formDataRef.current = entity;
    }, []);

    const onFormUpdated = useCallback((arg: { formContext: XrmFormContext }) => {
        formContext.current = arg.formContext;
        if (formContext.current.data.entity.getEntityName()) {
            const primaryAttributeValue = formContext.current.data.entity.getPrimaryAttributeValue();
            if (primaryAttributeValue) {
                setTitle(primaryAttributeValue);
            }
        }
    }, []);

    const onFooterUpdate = (arg: { currentTab: Form.Tab; executionContext: XrmEventContext }) => {
        const cells = arg.currentTab.tabFooter?.rows.flatMap(row => row.cells).filter(cell => cell.visible !== false && cell.control?.visible !== false);
        executionContext.current = arg.executionContext;
        setFooterButtonCells(cells ?? []);
    };

    const refreshForm = (arg: { entityId: string }) => {
        entityId.current = arg.entityId;
        setShouldRefresh(true);
        setDisabledNotification(null);
    };

    const onSetFormDisabled = (params: ComponentFramework.Dictionary) => {
        if (params.disabled) {
            setDisabledNotification(params.message);
        }
        else {
            setDisabledNotification(null);
        }
    };

    const onFooterButtonClick = (button: Form.Control) => {
        if (button.onClickEventHandlers) {
            for (const handler of button.onClickEventHandlers) {
                const parameters: any[] = [];
                if (handler.passExecutionContext) {
                    parameters.push(executionContext.current);
                }
                if (handler.parameters) {
                    const customParameters: string[] = handler.parameters.split(",");
                    for (const parameter of customParameters) {
                        parameters.push(parameter.slice(1, -1));
                    }
                }
                console.log(`Executing ${handler.functionName} function on ${button.id} button.`);
                executeFunctionByName(handler.functionName, window, parameters);
            }
        }
    };

    const dialogButtonsVisible = (): boolean => {
        if (footerButtonCells?.length === 0 || !executionContext.current) {
            return false;
        }
        return true;
    };

    const renderDialogButtons = (): JSX.Element[] => {
        const buttonPropsArray: IButtonProps[] = footerButtonCells.map((cell, index) => {
            return {
                key: index,
                text: cell.label,
                disabled: cell.control?.disabled,
                onClick: () => onFooterButtonClick(cell.control)
            };
        });
        return buttonPropsArray.map((buttonProps, index) => {
            if (index === 0 && formContext.current.ui.tabs.getLength() === 1) {
                return <PrimaryButton {...buttonProps} onClick={(e) => {
                    buttonProps.onClick?.(e);
                    props.onSaveCallback?.(formContext.current, formDataRef.current);
                }} />;
            }
            return <DefaultButton {...buttonProps} />;
        });
    };

    const onRenderFooterContentHandler = (): () => JSX.Element => {
        const entityId = props.parameters?.["entityId"];
        if (props.isQuickCreate) {
            return () => (
                <>
                    <PrimaryButton
                        text={entityId ? getLocalizedString("@definitions/RibbonDefinition/Mscrm.SaveAndClosePrimary") : getLocalizedString("@ComponentFramework/PropertyClasses/Navigation/openForm-title-create")}
                        onClick={async () => {
                            Xrm.Utility.showProgressIndicator(entityId ? getLocalizedString("components/dialogs/FormDialog/SavingChanges") : getLocalizedString("components/dialogs/FormDialog/CreatingRecord"));
                            const result = await executionContext.current.formContext.data.save();
                            Xrm.Utility.closeProgressIndicator();
                            if (result) {
                                props.onCreateCallback({
                                    id: result.id,
                                    entityType: result.entityType,
                                    name: executionContext.current.formContext.data.entity.getPrimaryAttributeValue() ?? null
                                });
                            }
                        }} />
                    <DefaultButton
                        text={getLocalizedString("components/dialogs/ErrorDialog/Close")}
                        onClick={onDismissHandler} />
                </>
            );
        }
        if (dialogButtonsVisible()) {
            return () => (
                <>
                    {renderDialogButtons()}
                </>
            );
        }
        return undefined;
    };

    const controlElement = <Control
        name="TALXIS.PCF.Portal.Form"
        bindings={{
            "entityName": {
                isStatic: true,
                value: props.parameters?.["entityName"]
            },
            "entityId": {
                isStatic: true,
                value: entityId.current,
            },
            "formId": {
                isStatic: true,
                value: props.parameters?.["formId"]
            },
            "extraQs": {
                isStatic: true,
                value: props.extraqs,
            },
            "formUniqueName": {
                isStatic: true,
                value: props.formName,
            },
            "isQuickCreate": {
                isStatic: true,
                value: props.isQuickCreate?.toString(),
            },
            "isDialog": {
                isStatic: true,
                value: "true"
            },
            "isNavbarLoaded": {
                isStatic: true,
                value: "true"
            },
            "DisplayCommandbar": {
                isStatic: true,
                value: !props.isQuickCreate && props?.parameters?.entityName ? "true" : "false"
            }
        }}
        childeventlisteners={[
            {
                eventname: "__onFormUpdated",
                eventhandler: onFormUpdated
            },
            {
                eventname: "onCreateCallback",
                eventhandler: (result) => {
                    entityId.current = result.entityId;
                    setIsNewRecord(true);
                    setShouldRefresh(true);
                    setDisabledNotification(null);
                }
            },
            // Do not confuse with props.onCloseCallback, those two are different
            // TODO: Sort out the difference between onCloseCallback and the one coming from props
            {
                eventname: "onCloseCallback",
                eventhandler: () => {
                    onDismissHandler();
                }
            },
            {
                eventname: "onDataChange",
                eventhandler: onDataChange
            },
            {
                eventname: "__onFooterUpdate",
                eventhandler: onFooterUpdate
            },
            {
                eventname: "__refreshForm",
                eventhandler: refreshForm
            },
            {
                eventname: "__setFormDisabled",
                eventhandler: onSetFormDisabled
            }
        ]}
        id={props.formId ?? props.formName}
        classId=""
        datafieldname={null}
        disabled={false}
        type={FormControlType.Field}
        visible={true}
        isUnbound={true}
        isRequired={false}
        definition={null}
    />;

    let dialogElement: JSX.Element;
    const onDismissHandler = async () => {
        if (props.onBeforeClose) {
            if (await props.onBeforeClose(formContext.current)) {
                appContext.closeFormDialog(props.id, formDataRef.current, null, props.formType === FormType.Dialog ? true : isNewRecord);
            }
        }
        else {
            appContext.closeFormDialog(props.id, formDataRef.current, null, props.formType === FormType.Dialog ? true : isNewRecord);
        }
    };

    switch (props.position) {
        case 2: // Side Panel
            dialogElement = <Panel
                customWidth={props.width ? props.width.toString() + 'px' : undefined}
                className={mergeStyles({
                    '.ms-Panel-main': {
                        overflow: 'hidden'
                    },
                    '.ms-Panel-commands': {
                        zIndex: 1
                    },
                    '.ms-Panel-footer': {
                        borderTop: `1px solid ${theme.semanticColors.bodyDivider}`,
                        background: theme.semanticColors.bodyBackground
                    },
                    '.ms-Panel-footerInner': {
                        display: 'flex',
                        gap: 10
                    },
                    '.ms-Panel-header': {
                        width: "75%",
                        padding: 0
                    },
                    '.ms-Panel-headerText': {
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                    },
                    '.ms-Panel-closeButton': {
                        margin: 0
                    },
                    '.ms-Panel-content>div>div>div>div .TALXIS__form > [class*="FormLoading_root"] > div': {
                        padding: 0
                    },
                    '.ms-Panel-content, .ms-Panel-content>div, .ms-Panel-content>div>div, .ms-Panel-content>div>div>div, .ms-Panel-content>div>div>div>div, .ms-Panel-content>div>div>div>div>.TALXIS__form': {
                        display: 'flex',
                        flexDirection: 'column',
                        flexGrow: 1,
                        minWidth: 0,
                        minHeight: 0
                    },
                    '.TALXIS__form__tab-content': {
                        padding: 2,
                        paddingTop: 15,
                        paddingBottom: 15
                    },
                    '.ms-Panel-content>div>div>div>div>.TALXIS__form': {
                        margin: 'initial'
                    },
                    '.ms-Panel-content': {
                        paddingBottom: dialogButtonsVisible() ? 0 : 15,
                        '>div>div>div>div>.TALXIS__form>.TALXIS__form__tab-wrapper': {
                            marginBottom: 0
                        }
                    }

                })}
                isOpen={true}
                hidden={false}
                isFooterAtBottom
                onRenderFooterContent={onRenderFooterContentHandler()}
                onDismiss={(ev) => {
                    if (!ev) {
                        onDismissHandler();
                        return;
                    }
                    const srcElement = ev.currentTarget as Element | null;
                    if (srcElement && srcElement.className.indexOf('ms-Button--icon') !== -1) {
                        onDismissHandler();
                        return;
                    }
                }}
                onOuterClick={() => { }}
                isLightDismiss={false}
                isBlocking={true}
                headerText={props.customTitle ?? title}>
                {/* TODO: Add support for handling all types of form notifications */}
                {disabledNotification !== null &&
                    <div>
                        <Notifications
                            collapseCount={2}
                            notifications={[{
                                // __disabledNotification is referenced in GlobalNotification to display the lock
                                id: "__disabledNotification",
                                notification: {
                                    level: 4,
                                    message: disabledNotification,
                                    type: 2
                                }
                            }]}
                        />
                    </div>
                }
                {props.formName && !shouldRefresh && controlElement}
            </Panel>;
            break;
        case -100:
            dialogElement = <div className={`${styles.inline} ${mergeStyles({
                '&': {
                    backgroundColor: theme.semanticColors.bodyBackground,
                    boxShadow: theme.semanticColors.cardShadow,
                    border: `1px solid ${theme.semanticColors.bodyDivider}`
                }
            })}`}>
                {controlElement}
                {dialogButtonsVisible() &&
                    <DialogFooter>
                        {renderDialogButtons()}
                    </DialogFooter>
                }
            </div>;
            break;
        case 1: // Center Dialog
        default:
            dialogElement = <Dialog
                {...props}
                height={props.height ?? '100%'}
                width={props.width ?? '100%'}
                onDismiss={() => {
                    onDismissHandler();
                }}

                modalProps={{
                    ...props.modalProps,
                    isBlocking: true,
                    className: `TALXIS__dialog--form ${props.modalProps.className} ${rootStyles} ${mergeStyles({
                        '.ms-Dialog-content.ms-Dialog-content': {
                            paddingBottom: dialogButtonsVisible() && 0
                        }
                    })}`
                }}
                dialogContentProps={{
                    ...props.dialogContentProps,
                    title: props.customTitle ?? title,

                }}>
                {/* TODO: Add support for handling all types of form notifications */}
                {disabledNotification !== null &&
                    <div>
                        <Notifications
                            collapseCount={2}
                            notifications={[{
                                // __disabledNotification is referenced in GlobalNotification to display the lock
                                id: "__disabledNotification",
                                notification: {
                                    level: 4,
                                    message: disabledNotification,
                                    type: 2
                                }
                            }]}
                        />
                    </div>
                }
                {!shouldRefresh && controlElement}
                {dialogButtonsVisible() &&
                    <DialogFooter>
                        {renderDialogButtons()}
                    </DialogFooter>
                }
            </Dialog>;
            break;
    }

    return dialogElement;
};

export default FormDialog;
FormDialog.defaultProps = defaultProps;