import { Hash, Liquid, TagToken, Tokenizer, TopLevelToken, assert, Template, TagImplOptions, evalQuotedToken, Emitter, Context, TagImpl } from "liquidjs";
import { RenderOptions } from "liquidjs/dist/liquid-options";
import { getMediaLibraryFileUrl, sendMetadataGetRequest } from "@definitions/MetadataApi";
import { HashValue } from "liquidjs/dist/template/tag/hash";
import { PlainObject } from "liquidjs/dist/context/scope";
import { FetchXml } from "@talxis/client-libraries/dist/utils/fetch-xml";

export class LiquidService {
    private _engine: Liquid = new Liquid();
    constructor() {
        this._engine.registerTag('codecomponent', new CodeComponentTag());
        this._engine.registerTag('medialibraryfile', new MediaLibraryFileTag());
        this._engine.registerTag('fetchxml', new FetchXmlTag());
    }
    public parseAndRender(html: string, scope?: object, renderOptions?: RenderOptions): Promise<any> {
        return this._engine.parseAndRender(html, scope, renderOptions);
    }
}

function readVariableName(tokenizer: Tokenizer) {
    const word = tokenizer.readIdentifier().content;
    if (word) return word;
    const quoted = tokenizer.readQuoted();
    if (quoted) return evalQuotedToken(quoted);
}

class FetchXmlTag implements TagImpl, TagImplOptions {
    variable: string;
    templates: Template[];
    liquid: Liquid;
    constructor() {
    }
    parse(token: TagToken, remainingTokens: TopLevelToken[]) {
        const tokenizer = new Tokenizer(token.args, this.liquid.options.operatorsTrie);
        this.variable = readVariableName(tokenizer);
        assert(this.variable, () => `${token.args} not valid identifier`);

        this.templates = [];

        const stream = this.liquid.parser.parseStream(remainingTokens);
        stream.on('tag:endfetchxml', () => stream.stop())
            .on('template', (tpl: Template) => this.templates.push(tpl))
            .on('end', () => {
                throw new Error(`tag ${token.getText()} not closed`);
            });
        stream.start();
    }
    * render(ctx: Context, emitter: Emitter, hash: HashValue) {
        const r = this.liquid.renderer;
        const fetch = new FetchXml(yield r.renderTemplates(this.templates, ctx)).builder;
        const records: Xrm.RetrieveMultipleResult = yield Xrm.WebApi.retrieveMultipleRecords(fetch.entity.name, `?fetchXml=${encodeURIComponent(fetch.toXml())}`);
        (ctx.bottom() as PlainObject)[this.variable] = records;
    }
}
class CodeComponentTag implements TagImpl, TagImplOptions {
    liquid: Liquid;
    args: Hash;
    parse(token: TagToken, remainingTokens: TopLevelToken[]) {
        this.args = new Hash(token.args);
    }
    * render(ctx: Context, emitter: Emitter, hash: HashValue) {
        // @ts-ignore - We are not going to fix LiquidJS types at the moment
        const parameters = yield this.args.render(ctx);
        const data = {
            name: parameters.name,
            bindings: {}
        };
        delete parameters.name;
        data.bindings = parameters;
        emitter.write(`<div data-pcf-control='${JSON.stringify(data)}'></div>`);
    }
}
class MediaLibraryFileTag implements TagImpl, TagImplOptions {
    liquid: Liquid;
    args: Hash;
    parse(token: TagToken, remainingTokens: TopLevelToken[]) {
        this.args = new Hash(token.args);
    }
    * render(ctx: Context, emitter: Emitter, hash: HashValue) {
        // @ts-ignore - We are not going to fix LiquidJS types at the moment
        const parameters = yield this.args.render(ctx);
        const mediaLibraryFile: Response = yield sendMetadataGetRequest(`v9.1/talxis_medialibraryfiles(${parameters.id})?$select=talxis_slug`);
        if (mediaLibraryFile.ok) {
            // @ts-ignore - We are not going to fix LiquidJS types at the moment
            const body = yield mediaLibraryFile.json();
            const slug: string = body["talxis_slug"];
            if (slug) {
                emitter.write(`${getMediaLibraryFileUrl(`/media${(slug.startsWith("/") ? "" : "/")}${slug}`)}`);
            }
        }
    };
}