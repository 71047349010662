
import { IQuickFindView, IViewDefinition } from '@controls/native/View/interfaces/viewdefinition';
import { AppComponents } from '@configuration/AppComponents';
import { LocalizeLabel } from '@localization/helpers';
import { IPromiseCache, cachedWrapper } from '@utilities/MemoryCachingHelpers';
import { sanitizeGuid } from '../../Functions';
import { sendMetadataGetRequest, metadataRetrieveMultiple } from './MetadataApi';
import { FetchXml } from '@talxis/client-libraries';

export interface IViewIdName {
    queryid: string,
    name: string;
    isOwnedByCurrentUser: boolean;
}

export class ViewDefinition {
    private static _viewDefinitions: IPromiseCache<IViewDefinition> = {};
    private static _quickFindViewDefinitions: IPromiseCache<IQuickFindView> = {};
    private static _lookupViewDefinitions: IPromiseCache<IQuickFindView> = {};
    private static _entityViewIdNameMap: IPromiseCache<IViewIdName[]> = {};

    static async getAsync(queryId: string): Promise<IViewDefinition> {
        queryId = sanitizeGuid(queryId);
        return cachedWrapper(queryId, () => new Promise(async (resolve, reject) => {
            try {
                //savedquery
                if (FetchXml.GetQueryType(queryId) === 'savedquery') {
                    const response = await sendMetadataGetRequest(`v9.1/savedqueries(${queryId})?$select=name,savedqueryid,fetchxml,returnedtypecode,layoutjson`);
                    const result = await response.json();
                    const viewDefinition: IViewDefinition = {
                        name: result.name,
                        queryid: result.savedqueryid,
                        fetchxml: result.fetchxml,
                        layoutjson: result.layoutjson,
                        returnedtypecode: result.returnedtypecode
                    };
                    resolve(viewDefinition);
                }
                //user query
                else {
                    const response = await window.Xrm.WebApi.retrieveRecord('talxis_userquery', queryId);
                    const viewDefinition: IViewDefinition = {
                        name: response['talxis_name'],
                        queryid: response['talxis_userqueryid'],
                        fetchxml: response['talxis_fetchxml'],
                        layoutjson: response['talxis_layoutjson'],
                        returnedtypecode: response['talxis_returnedtypecode']
                    };
                    resolve(viewDefinition);
                }
            }
            catch (err) {
                reject(err);
            }
        }), this._viewDefinitions);
    };

    static clearViewFromCache(viewId: string) {
        this._viewDefinitions[viewId] = null;
    }

    static clearEntityViewNameIdCache(entityName: string) {
        Object.keys(this._entityViewIdNameMap).map(key => {
            if (key.startsWith(entityName)) {
                this._entityViewIdNameMap[key] = null;
            }
        });
    }

    static async getQuickFindViewAsync(entityName: string): Promise<IQuickFindView> {
        return cachedWrapper(entityName, () => new Promise(async (resolve, reject) => {
            const quickFindDefinitions = await metadataRetrieveMultiple(`v9.1/savedqueries?$select=fetchxml,layoutjson&$filter=querytype eq 4 and isquickfindquery eq true and returnedtypecode eq '${entityName}'`);

            if (quickFindDefinitions.entities && quickFindDefinitions.entities[0]) {
                resolve(quickFindDefinitions.entities[0] as IQuickFindView);
            }
            else {
                reject(`Didn't find a Quick Find View for entity ${entityName}`);
            }
        }), this._quickFindViewDefinitions);
    };

    static async getLookupViewAsync(entityName: string): Promise<IQuickFindView> {
        return cachedWrapper(entityName, () => new Promise(async (resolve, reject) => {
            // Attempt to obtain lookup view from App Module
            const appModuleViewIds = await AppComponents.filterAppComponents(entityName, 26);
            if (appModuleViewIds.length > 0) {
                const lookupViews = await metadataRetrieveMultiple(`v9.1/savedqueries?$select=fetchxml&$filter=querytype eq 64 and (${appModuleViewIds.map(x => `savedqueryid eq ${x}`).join(" or ")})`);
                if (lookupViews.entities.length > 0) {
                    if (lookupViews.entities.length > 1) {
                        console.warn(`Found multiple lookup views in App Module for entity ${entityName}, selecting first one!`);
                    }
                    resolve(lookupViews.entities[0] as IQuickFindView);
                    return;
                }
            }

            // No lookup views found in the App Module, fall back to global search
            const quickFindDefinitions = await metadataRetrieveMultiple(`v9.1/savedqueries?$select=fetchxml&$filter=isdefault eq true and querytype eq 64 and returnedtypecode eq '${entityName}'`);

            if (quickFindDefinitions.entities && quickFindDefinitions.entities[0]) {
                resolve(quickFindDefinitions.entities[0] as IQuickFindView);
            }
            else {
                reject(`Didn't find a Quick Find View for entity ${entityName}`);
            }
        }), this._lookupViewDefinitions);
    };

    static async getViewNamesAndIds(entityName: string, options?: {
        viewIds?: string[],
        includeUserQueries?: boolean
    }): Promise<IViewIdName[]> {
        const viewIds = options.viewIds;
        const includeUserQueries = options.includeUserQueries;
        const key = `${entityName}_${viewIds?.join('_')}_${includeUserQueries ?? ''}`;
        return cachedWrapper(key, () => new Promise(async (resolve) => {
            let _viewIds = viewIds;
            if (!_viewIds) {
                _viewIds = await AppComponents.filterAppComponents(entityName, 26);
            }

            let filter = `?$filter=returnedtypecode eq '${entityName}' and querytype eq 0&$select=name,savedqueryid`;
            if (_viewIds?.length > 0) {
                filter = `?$select=name,savedqueryid&$filter=querytype eq 0 and (${_viewIds.map(x => `savedqueryid eq ${x}`).join(' or ')})`;
            }
            const [systemViews, userViews] = await Promise.all([
                metadataRetrieveMultiple(`v9.1/savedqueries${filter}`),
                //TODO: fetch shared user queries as well
                includeUserQueries ? window.Xrm.WebApi.retrieveMultipleRecords('talxis_userquery', `?$select=talxis_name,talxis_userqueryid&$filter=talxis_returnedtypecode eq '${entityName}' and _talxis_ownerprincipalid_value eq '${window.Xrm.Utility.getGlobalContext().userSettings.userId}'`) : { entities: [] }
            ]);
            let views: IViewIdName[] = [];

            for (const view of userViews.entities) {
                views.push({
                    queryid: view["talxis_userqueryid"],
                    name: view['talxis_name'],
                    isOwnedByCurrentUser: true
                });
            }
            for (const view of systemViews.entities) {
                views.push({
                    queryid: view["savedqueryid"],
                    name: LocalizeLabel(view["__labels"]?.["name"]) ?? view.name,
                    isOwnedByCurrentUser: false,
                });
            }
            resolve(views);
        }), this._entityViewIdNameMap);
    }

}