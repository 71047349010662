import { ControlLoader } from "@src/app/classes/loaders/ControlLoader";
import { ICdsOptions, IExtendedXrmGridControl, IGridNativeStateValues, IInternalDatasetControlProps, XrmGridType } from "./interfaces";
import { ICustomControl } from "../interfaces/customcontrol";
import { ViewSelector } from "./ViewSelector/ViewSelector";
import { Context } from "@src/ComponentFramework/PropertyClasses/Context";
import { State } from "@src/providers/HistoryProvider/State";
import { HistoryManager } from "@src/providers/HistoryProvider/HistoryManager";
import { IFormContext } from "../native/Form/interfaces/IFormContext";
import { QuickFind } from "./QuickFind/QuickFind";
import { GridContext } from "./GridContext";
import { Constants, Sanitizer } from "@talxis/client-libraries";
import { DatasetRibbon } from "@src/app/classes/models/Ribbon/DatasetRibbon";
import { UserSettingsDefinition } from "@src/app/classes/definitions/UserSettingsDefinition";
import { Grid } from "./Grid";

interface IState {
    values: React.MutableRefObject<IGridNativeStateValues>;
    setDefaultValues: (state: IGridNativeStateValues) => void;
}

export class DatasetControl {
    private _mounted = true;
    private _dataset: Grid;
    private _controlProps: IInternalDatasetControlProps;
    private _control: ICustomControl
    private _controlInstance: ComponentFramework.StandardControl<any, any>;
    private _viewSelector: ViewSelector;
    private _ribbon: DatasetRibbon;
    private _quickFind: QuickFind;
    private _initialRender = true;
    private _container: HTMLDivElement;
    private _stateRef: React.MutableRefObject<State>;
    private _formContext: IFormContext | undefined;
    private _gridContext: IExtendedXrmGridControl;
    private _cdsOptions: ICdsOptions;
    private _state: IState;

    constructor(dataset: Grid, container: HTMLDivElement, controlProps: IInternalDatasetControlProps, stateRef: React.MutableRefObject<State>, state: IState, formContext?: IFormContext) {
        this._dataset = dataset;
        this._dataset._setRenderer(() => this.render());
        this._container = container;
        this._stateRef = stateRef;
        this._controlProps = controlProps;
        this._state = state;
        this._formContext = formContext;
        this._viewSelector = new ViewSelector(dataset);
        this._ribbon = new DatasetRibbon(dataset);
        this._quickFind = new QuickFind(dataset);
        this._gridContext = new GridContext(this._dataset);
    }

    public async init() {
        await this._initializeControlInstance();
        this._cdsOptions = this._getCdsOptions();
        this._registerActionsAndEvents();
        HistoryManager.activeControls.add(this._controlInstance);
    }

    public async mount() {
        this._mounted = true;
        const selectedRecordIds = [...this._dataset.getSelectedRecordIds()];
        if (this._dataset.paging.pageNumber !== 1) {
            await this._dataset.paging.loadExactPage(this._dataset.paging.pageNumber);
        }
        else {
            await this._dataset.refresh();
        }
        //we need to do this because refreshs clears selected records, but we might have some loaded from state
        this._dataset.setSelectedRecordIds(selectedRecordIds);

    }
    public unmount() {
        this._mounted = false;
        this._state.values.current.selectedRecordIds = this._dataset.getSelectedRecordIds();
        this._mounted = false;
        this._controlInstance?.destroy();
        if (this.type === XrmGridType.HomepageGrid) {
            window.Xrm.Page = undefined;
        }
        HistoryManager.activeControls.delete(this._controlInstance);
        this._ribbon.destroy();
    }

    public async render() {
        let context = await Context.createDatasetContext(this._dataset, this._controlProps, this._control, this._stateRef.current);
        context = this._dataset._patchContext(context) as any;
        if (this._initialRender) {
            this._controlInstance.init(context, () => this._notifyOutputChanged(), this._stateRef.current.getValues('pcf'), this._container);
        }
        if (this._mounted) {
            this._controlInstance.updateView(context);
        }
        this._initialRender = false;
    }

    public setDefaultStateValues(
        columns?: ComponentFramework.PropertyHelper.DataSetApi.Column[],
        linkedEntities?: ComponentFramework.PropertyHelper.DataSetApi.LinkEntityExposedExpression[],
        sorting?: ComponentFramework.PropertyHelper.DataSetApi.SortStatus[],
        dataSource?: string,
        selectedRecordIds?: string[]
    ) {
        this._state.setDefaultValues({
            columns: columns ?? [],
            linkedEntities: linkedEntities ?? [],
            sorting: sorting ?? [],
            userFilter: null,
            dataSource: dataSource ?? null,
            selectedRecordIds: selectedRecordIds ?? [],
            searchValue: "",
            pageNumber: 1,
            pageSize: this._getDefaultPageSize()
        });
        this._setGridState();
    }

    public get ribbon() {
        return this._ribbon;
    }

    public get viewSelector() {
        return this._viewSelector;
    }

    public get quickFind() {
        return this._quickFind;
    }

    public get gridContext() {
        return this._gridContext;
    }

    public get cdsOptions() {
        return this._cdsOptions;
    }

    public get datasetBindingName() {
        return this._control.manifest.datasets[0].name;
    }

    public get bindings() {
        return this._controlProps.bindings;
    }

    public get state() {
        return this._state;
    }

    public get formContext() {
        return this._formContext;
    }

    public getControlProps() {
        return this._controlProps;
    }

    public get type() {
        if (this.bindings.IsViewPage) {
            return XrmGridType.HomepageGrid;
        }
        return XrmGridType.Subgrid;
    }

    public getOutputs() {
        return this._controlInstance.getOutputs();
    }

    private _registerActionsAndEvents() {
        this._formContext?.registerChildControl(this._controlProps.id, {
            gridRefresh: () => this._dataset.refresh()
        });
        //make refreshing of the homepage grid avaliable
        if (this.type === XrmGridType.HomepageGrid) {
            Xrm.Page = {
                data: {
                    //@ts-ignore - not implementing promise like
                    refresh: () => {
                        this._dataset.refresh();
                    }
                }
            };
        }
        this._dataset.addEventListener('onRecordsSelected', ids => this._onRecordsSelected(ids));
        this._dataset.addEventListener('onDatasetItemOpened', entityReference => this._onDatasetItemOpened(entityReference));
        this._dataset.addEventListener('onPageSizeChanged', pageSize => this._onPageSizeChanged(pageSize));
    }

    private _setGridState() {
        if (this._state.values.current.dataSource) {
            this._dataset.setDataSource(this._state.values.current.dataSource);
        }
        if (this._state.values.current.columns.length > 0) {
            this._dataset.setColumns(this._state.values.current.columns);
        }
        this._dataset.setSelectedRecordIds(this._state.values.current.selectedRecordIds);
        this._dataset.setSearchQuery(this._state.values.current.searchValue);
        this._dataset.paging.setPageSize(this._state.values.current.pageSize);
        this._dataset.filtering.setFilter(this._state.values.current.userFilter);
        this._state.values.current.linkedEntities.map(x => this._dataset.linking.addLinkedEntity(x));
        this._dataset.sorting = this._state.values.current.sorting;
    }

    private _getDefaultPageSize() {
        if (this.bindings.RecordsPerPage) {
            return parseInt(this.bindings.RecordsPerPage.value);
        }
        if (this.type === XrmGridType.HomepageGrid) {
            return Constants.DEFAULT_PAGE_SIZE_GRID;
        }
        return Constants.DEFAULT_PAGE_SIZE_SUBGRID;
    }

    private async _initializeControlInstance() {
        this._control = await (await ControlLoader.getAsync(this._controlProps.name)).load();
        //@ts-ignore - constructor not part of types
        this._controlInstance = new this._control.code();
    }

    private _getCdsOptions(): ICdsOptions | null {
        const inputString = this._control.manifest.datasets[0].cdsOptions;
        let jsonObject: any = {};
        if (!inputString) {
            //the default values should correspond to the default subgrid settings
            jsonObject = {
                displayCommandBar: true,
                displayIndex: false,
                displayPaging: true,
                displayQuickFind: false,
                displayViewSelector: false,
            } as ICdsOptions;
        }
        else {
            //lowest priority => cdsOptions from manifest
            try {
                const keyValuePairs = inputString.split(';');
                for (const pair of keyValuePairs) {
                    if (!pair) continue;
                    const [key, value] = pair.split(':');
                    const formattedKey = `${key.trim()}`; // Add double quotes around the key
                    jsonObject[formattedKey] = JSON.parse(value.trim()); // Parse the value and add it to the object
                }
            }
            catch (err) {
                throw new Error(`Invalid cdsOptions parameter in control ${this._controlProps.name}`);
            }
        }
        //medium priority => virtual grid dataset binding
        const cdsOptions = {
            ...jsonObject,
            ...this._controlProps.bindings[this.datasetBindingName]?.DataSetUIOptions
        } as ICdsOptions;
        //highest priority => bindings for specific attributes 
        if (this._controlProps.bindings.EnableQuickFind) {
            cdsOptions.displayQuickFind = this._controlProps.bindings.EnableQuickFind.value === 'true';
        }
        if (this._controlProps.bindings.EnableViewPicker) {
            cdsOptions.displayViewSelector = this._controlProps.bindings.EnableViewPicker.value === 'true';
        }
        if (this._controlProps.bindings.DisplayCommandBar) {
            cdsOptions.displayCommandBar = this._controlProps.bindings.DisplayCommandBar.value === 'true';
        }
        if (this._controlProps.bindings.DisplayPaging) {
            cdsOptions.displayPaging = this._controlProps.bindings.DisplayPaging.value === 'true';
        }
        return cdsOptions;
    }
    private _onRecordsSelected(ids: string[]) {
        if (ids.length === 1) {
            const selectedRecord = this._dataset.records[ids[0]];
            const executionContext: Xrm.Events.EventContext = {
                //@ts-ignore - We are not implementing entire EventContext, only required items by the scripts.
                getFormContext: () => {
                    return {
                        data: {
                            entity: {
                                getId: () => {
                                    return selectedRecord.getRecordId();
                                },
                                attributes: {
                                    get: (arg1?: any): any => {
                                        if (typeof arg1 === "string") {
                                            return {
                                                getValue: () => selectedRecord.getValue(arg1)
                                            } as Xrm.Attributes.Attribute;
                                        }
                                    }
                                }
                            }
                        }
                    };
                }
            };
            this.formContext?.onRecordSelect(this._controlProps.id, executionContext);
        }
        if (ids.length > 1) {
            const executionContext: Xrm.Events.EventContext = {
                //@ts-ignore - We are not implementing entire EventContext, only required items by scripts.
                getFormContext: () => {
                    return {
                        getSelectedRows: () => {
                            return ids;
                        }
                    };
                }
            };
            this.formContext?.onRecordsSelect(this._controlProps.id, executionContext);
        }
        this.bindings[this.datasetBindingName]?.onRecordsSelected(ids);
        this.ribbon.refresh();
    }

    private _onDatasetItemOpened(entityReference: ComponentFramework.EntityReference) {
        entityReference = Sanitizer.Lookup.getEntityReference(entityReference);
        const openForm = () => {
            window.Xrm.Navigation.openForm({
                entityName: entityReference.etn,
                entityId: entityReference.id.guid,
            });
        };
        //opening other entity lookup
        if (this._dataset.getTargetEntityType() !== entityReference.etn) {
            openForm();
            return;
        }
        (async () => {
            const openRecordCommand = await this.ribbon.getCommand('Mscrm.OpenRecordItem');
            if (openRecordCommand) {
                openRecordCommand.execute();
                return;
            }
            openForm();
        })();
    }

    private _onPageSizeChanged(pageSize: number) {
        if (this.type === XrmGridType.HomepageGrid && this._mounted) {
            UserSettingsDefinition.getUserSettings().setPagingLimit(pageSize);
        }
    }

    private _notifyOutputChanged() {
        this.formContext?.xrmExecutionContext?.formContext._onOutputChangedHandlerMap.get(this._controlProps.id)?.map(handler => handler({
            ...this.formContext.xrmExecutionContext,
            //@ts-ignore - typings
            getFormContext: () => this.gridContext
        }));
    }
}