import { Dataset, DataTypes, FetchXmlBuilder, FetchXmlDataProvider, IDataset, IRecord, Operators, Type } from "@talxis/client-libraries";
import { Grid } from "../Grid";

export class ViewManager {
    private _grid: Grid;
    private _onInitialized: () => void;
    private _dataset: IDataset

    constructor(grid: Grid, onInitialized: () => void) {
        this._grid = grid;
        this._onInitialized = onInitialized;
        this._init();
    }

    public get dataset() {
        return this._dataset;
    }
    public async saveChanges() {
        await this._dataset.save();
        this._grid.viewSelector.refresh(true);
    }
    private async _deleteView(record: IRecord) {
        if (!window.confirm(window.TALXIS.Portal.Translations.getLocalizedString('@pages/control/View/ViewSelector/DeleteView'))) {
            return;
        }
        window.Xrm.Utility.showProgressIndicator(`${window.TALXIS.Portal.Translations.getLocalizedString('@pages/control/View/ViewSelector/DeleteInProgress')}...`);
        await window.Xrm.WebApi.deleteRecord('talxis_userquery', record.getRecordId());
        record.clearChanges();
        window.Xrm.Utility.closeProgressIndicator();
        this.dataset.refresh();
        await this._grid.viewSelector.refresh(true);
        if (record.getRecordId() === this._grid.getViewId()) {
            const availabeViews = await this._grid.viewSelector.getViewNamesAndIds();
            this._grid.viewSelector.setCurrentView({
                entityType: this._grid.getTargetEntityType(),
                id: availabeViews[0].queryid
            });
        }
    }
    private async _init() {
        const fetchXml = new FetchXmlBuilder.fetch(
            new FetchXmlBuilder.entity('talxis_userquery', [
                new FetchXmlBuilder.attribute('talxis_name')
            ]));
        fetchXml.entity.addFilter(new FetchXmlBuilder.filter(Type.And.Name, [
            new FetchXmlBuilder.condition('talxis_returnedtypecode', Operators.Equal.Name, [new FetchXmlBuilder.value(this._grid.getTargetEntityType())]),
            new FetchXmlBuilder.condition('talxis_ownerprincipalid', Operators.Equal.Name, [new FetchXmlBuilder.value(window.Xrm.Utility.getGlobalContext().userSettings.userId)])
        ]));
        const fetchXmlDataProvider = new FetchXmlDataProvider(fetchXml.toXml());
        fetchXmlDataProvider.setColumns([
            {
                name: 'talxis_name',
                visualSizeFactor: 100,
                isDraggable: false
            },
            {
                name: 'talxis_description',
                visualSizeFactor: 100,
                isDraggable: false,
                dataType: DataTypes.SingleLineText
            },
            {
                name: 'talxis_layoutjson',
                visualSizeFactor: 100,
                isDraggable: false,
                isHidden: true
            },
            {
                name: 'talxis_ribbon__virtual',
                type: 'action',
                displayName: '',
                isDraggable: false,
                visualSizeFactor: 30
            }
        ]);
        this._dataset = new Dataset(fetchXmlDataProvider);
        this._dataset.addEventListener('onRecordLoaded', async (record) => {
            this._registerExpressions(record);
            if (record.getRecordId() === this._grid.getViewId()) {
                record.setValue('talxis_layoutjson', await this._grid.viewSelector.getLayoutJSONFromColumns(this._grid.columns));
            }
        });
        this._dataset.addEventListener('onAfterRecordSaved', (record) => {
            if (record.getRecordId() === this._grid.getViewId()) {
                //save changes to current view
                this._grid.viewSelector.saveExistingView(true);

            }
        });
        this._onInitialized();
    }
    private _registerExpressions(record: IRecord) {
        record.expressions?.ui.setNotificationsExpression('talxis_ribbon__virtual', () => {
            return [{
                iconName: 'Delete',
                title: window.TALXIS.Portal.Translations.getLocalizedString('@definitions/RibbonDefinition/Mscrm.DeletePrimaryRecord'),
                messages: [],
                compact: true,
                uniqueId: 'remove',
                actions: [{
                    actions: [() => this._deleteView(record)]
                }]
            }];
        });
    }
}