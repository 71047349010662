import React from 'react';
import ReactDOM from "react-dom";
import { IMessageBarProps } from './interfaces/IMessageBarProps';
import { MessageBar } from './components/MessageBar';
import { Liquid } from "liquidjs";
import { FileComponent } from './components/FileComponent';
import { IFileComponentProps } from './interfaces/IFileComponentProps';
import { FileAttribute, IFileAttributeConnection } from '@talxis/client-libraries/dist/utils/file';
import { IFileObject } from '../../interfaces/IFileObject';

interface IInputs {
    value: ComponentFramework.PropertyTypes.Property;
}
interface IOutputs {
    value?: IFileObject;
}

export class FileControl implements ComponentFramework.StandardControl<IInputs, IOutputs> {
    private _context: ComponentFramework.Context<IInputs>;
    private _notifyOutputChanged: () => void;
    private _container: HTMLDivElement;
    private _liquid: Liquid;
    private _storage: FileAttribute;
    private _connection: IFileAttributeConnection;
    private _fileObject: IFileObject;
    private _isUploadInProgress: boolean;
    private _isControlDisabled: boolean;

    constructor() {
        this._liquid = new Liquid();
    }

    public init(context: ComponentFramework.Context<IInputs>, notifyOutputChanged: () => void, state: ComponentFramework.Dictionary, container: HTMLDivElement) {
        this._context = context;
        this._notifyOutputChanged = notifyOutputChanged;
        this._container = document.createElement("div");
        this._fileObject = this._context.parameters.value.raw;
        this._storage = new FileAttribute(this._context.webAPI);
        this._isControlDisabled = this._context.mode.isControlDisabled || false;
        //@ts-ignore - context.page is not existing official ComponentFramework.Context interface, but it exists Portal
        if (!this._context.page.entityId) {
            const messageBarProps: IMessageBarProps = {
                getLocalizedString: this._getLocalizedString.bind(this)
            };
            ReactDOM.render(React.createElement(MessageBar, messageBarProps), this._container);
        }
        container.appendChild(this._container);
    }

    public updateView(context: ComponentFramework.Context<IInputs>): void {
        this._context = context;

        //@ts-ignore - context.page is not existing official ComponentFramework.Context interface, but it exists Portal
        if (this._context.page.entityId) {
            this._connection = {
                fileAttribute: this._context.parameters.value.attributes.LogicalName,
                //@ts-ignore - context.page is not existing official ComponentFramework.Context interface, but it exists Portal
                entityName: this._context.page.entityTypeName,
                //@ts-ignore - context.page is not existing official ComponentFramework.Context interface, but it exists Portal
                recordId: this._context.page.entityId,
            };
            this._fileObject = this._context.parameters.value.raw ?? undefined;
            const fileComponentProps: IFileComponentProps = {
                fileName: this._fileObject?.["fileName"],
                isUploadInProgress: this._isUploadInProgress,
                isControlDisabled: this._isControlDisabled,
                onClickDownload: this._onClickDownload.bind(this),
                onClickDelete: this._onClickDelete.bind(this),
                onClickUpload: this._onClickUpload.bind(this),
                getLocalizedString: this._getLocalizedString.bind(this),
            };
            ReactDOM.render(React.createElement(FileComponent, fileComponentProps), this._container);
            this._isUploadInProgress = undefined;
        }
    }

    public getOutputs(): IOutputs {
        return {
            value: this._fileObject
        };
    }

    public destroy(): void {
    }

    private async _onClickDownload() {
        await this._storage.downloadFileFromAttribute(this._connection, true);
    }
    private async _onClickDelete() {
        const confirmStrings: ComponentFramework.NavigationApi.ConfirmDialogStrings = {
            text: this._getLocalizedString("deleteFile"),
        };
        const result = await this._context.navigation.openConfirmDialog(confirmStrings);
        if (result.confirmed) {
            this._fileObject = null;
            this._notifyOutputChanged();
        } else {
            return false;
        }
    }
    private async _onClickUpload() {
        const that = this;
        const fileUploadButton = document.createElement('input');
        fileUploadButton.id = 'file-upload-button';
        fileUploadButton.type = 'file';
        fileUploadButton.hidden = true;
        fileUploadButton.click();
        fileUploadButton.onchange = async function () {
            //@ts-ignore - context.factory.createFileObject() is not part of  official ComponentFramework.Context interface, but it exists Portal
            that._fileObject = that._context.factory.createFileObject(fileUploadButton.files[0]);
            that._notifyOutputChanged();
            that._isUploadInProgress = true;
            that.updateView(that._context);
        };
    }

    private _getLocalizedString(string: string, variables = {}): string {
        return Object.keys(variables).length !== 0 ? this._liquid.parseAndRenderSync(this._context.resources.getString(string), variables) : this._context.resources.getString(string);
    }
}