import React, { createContext, useEffect, useRef, useState, useMemo } from 'react';
import { SitemapDefinition } from '@src/app/classes/definitions/SitemapDefinition';
import { AppModule } from '@src/app/classes/configuration/AppModule';
import { Page } from './Page';
import { NavigationPromptDialog, INavigationPromptDialogProps } from '@src/components/dialogs/NavigationPromptDialog';
import { createBrowserHistory } from 'history';
import { replaceLast } from '@src/app/Functions';
import { HistoryManager } from './HistoryManager';
import MainLoading from '@src/components/loadings/MainLoading';

interface IHistoryContext {
    currentPage: Page;
}

export const history = createBrowserHistory();

export const HistoryContext = createContext<IHistoryContext>(null);

/**
 * Listens to all history changes and creates an internal portal history of visited pages.
 * This allows us to assign a state to each page and keep track of the navigation context which helps with sitemap higlighting.
 * Also servers as an interceptor to check if there are any unsaved changes and if so, prevents the navigation from going further until user confirms it.
 */
export const HistoryProvider: React.FC = ({ children }) => {

    const currentPageRef = useRef<Page>(useMemo(() => {
        return HistoryManager.getSessionPage();
    }, []));
    const unblockNavigationRef = useRef<boolean>(false);
    const disableNavigationRef = useRef<boolean>(false);
    const lastBlockedPopActionRef = useRef<string>(null);
    const [currentPage, setCurrentPage] = useState<Page>(currentPageRef.current);
    const navigationOverridenRef = useRef<boolean>(false);
    const [navigationDialogProps, setNavigationDialogProps] = useState<INavigationPromptDialogProps>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        HistoryManager.setCurrentPageRef(currentPageRef);
        HistoryManager.showLoading = () => {
            setIsLoading(true);
        };
        const navigate = (location: any) => {
            if (lastBlockedPopActionRef.current !== 'POP') {
                history.push(`${location.pathname}${location.search}${window.location.hash}`);
            }
            else {
                if (currentPageRef.current.index > location.state) {
                    history.goBack();
                }
                else {
                    history.goForward();
                }
            }
        };
        //empty return unblocks the navigation, return false prevents it
        history.block((location, action) => {
            const pagePath = decodeURIComponent(`${location.pathname}${location.search}`);
            //two conditions below override every push/replace, this needs to be done so that pageIndex can be
            //pushed to the native history state
            if (action === 'PUSH' && !navigationOverridenRef.current) {
                navigationOverridenRef.current = true;
                history.push(`${location.pathname}${location.search}${window.location.hash}`, currentPageRef.current ? currentPageRef.current?.index + 1 : 0);
                return false;
            }
            if (action === 'REPLACE' && !navigationOverridenRef.current) {
                navigationOverridenRef.current = true;
                history.replace(`${location.pathname}${location.search}${window.location.hash}`, currentPageRef.current.index);
                return false;
            }
            navigationOverridenRef.current = false;
            //from here, we handle normal navigation logic since now we have the current index in state
            //block the navigation if we are on the first page and the user is pressing the back button
            if (action === 'POP' && currentPageRef.current?.isFirst() && !location.state) {
                disableNavigationRef.current = true;
                return false;
            }
            //block the navigation if navigating to the same page as before
            if (pagePath === currentPageRef.current?.path) {
                disableNavigationRef.current = true;
                return false;
            }
            disableNavigationRef.current = false;
            //explicitly allow navigation, used when we are navigating from form with unsaved changes present and the user clicked the discard changes buttton
            if (unblockNavigationRef.current) {
                unblockNavigationRef.current = false;
                return;
            }
            //legacy condition so the navigation dialog pops up in same cases as before
            //not sure this should always be the case, but this is the way it was implemented before
            //if this condition is missing, almost every form on MPG has the notification appear since it outputs unsaved changes
            if (currentPageRef.current?.entityName === 'talxis_portalpage') {
                return;
            }
            //allow navigation if no unsaved changes are present
            if (!currentPageRef.current?.isDirty()) {
                return;
            }
            //TODO: this case should be needed when form gets refactored
            //this is to prevent unsaved changes dialog when new record gets created
            if (action === 'REPLACE') {
                return;
            }
            //unsaved changes present, show the dialog
            setNavigationDialogProps({
                onDiscard: () => {
                    unblockNavigationRef.current = true;
                    navigate(location);
                    setNavigationDialogProps(null);
                },
                onDismiss: () => {
                    setNavigationDialogProps(null);
                },
                onSave: async () => {
                    setNavigationDialogProps(null);
                    //HomepageGrid
                    if (currentPageRef.current.getDataset()) {
                        window.Xrm.Utility.showProgressIndicator(window.TALXIS.Portal.Translations.getLocalizedString('@definitions/RibbonDefinition/Saving'));
                        try {
                            await currentPageRef.current.getDataset().save();
                            navigate(location);
                        }
                        //TODO: implement proper save error handling on dataset
                        catch (err) {
                            throw err;
                        }
                        window.Xrm.Utility.closeProgressIndicator();
                    }
                    //form
                    else {
                        const formContext = currentPageRef.current.getFormContext();
                        window.Xrm.Utility.showProgressIndicator(window.TALXIS.Portal.Translations.getLocalizedString('@definitions/RibbonDefinition/Saving'));
                        const result = await formContext.data.save();
                        if (!result) {
                            window.Xrm.Utility.closeProgressIndicator();
                            return;
                        }
                        navigate(location);
                        window.Xrm.Utility.closeProgressIndicator();
                    }
                }
            });
            lastBlockedPopActionRef.current = action;
            disableNavigationRef.current = true;
            return false;
        });
    }, []);

    useEffect(() => {
        history.listen((location, action) => {
            if (disableNavigationRef.current || navigationOverridenRef.current) {
                return;
            }
            const pagePath = decodeURIComponent(`${location.pathname}${location.search}`);
            //handling of navigating to new pages, this syncs the internal portal history with browser history
            if (action !== 'POP') {
                const previousPage = action === 'PUSH' ? currentPageRef.current : currentPageRef.current?.previousPage;
                //TODO: we should decide if we want to reuse the previous page state on navigating to a page that has already been visited
                // currently a new instance of page is created, but we can simply point to the existing instance instead as in the case of back/forward buttons
                const page = new Page(pagePath, location.state as number, previousPage);
                if (action === 'REPLACE') {
                    page.setNextPage(currentPageRef.current?.nextPage);
                }
                currentPageRef.current = page;
                setCurrentPage(page);
                return;
            }
            //handling of navigation to previous/next pages
            //go back
            if (currentPageRef.current.index > location.state) {
                const previousPage = currentPageRef.current.previousPage;
                if (!previousPage) {
                    return;
                }
                previousPage.setNextPage(currentPageRef.current);
                currentPageRef.current = previousPage;
                setCurrentPage(previousPage);
            }
            //go forward
            else {
                currentPageRef.current = currentPageRef.current.nextPage;
                setCurrentPage(currentPageRef.current);
            }
            return;
        });
    }, []);

    useEffect(() => {
        if (!currentPage) {
            return;
        }
        console.log(`URL has changed. Current page path: ${currentPage.path} \n Current page index: ${currentPage.index}`);
        if (HistoryManager.isUserOnLandingPage()) {
            history.push(SitemapDefinition.getCurrentSiteMap().getFirstSitemapSubArea().url);
        }
    }, [currentPage]);

    useEffect(() => {
        if (currentPageRef.current) {
            return;
        }
        //initial load
        if (HistoryManager.isUserOnLandingPage()) {
            history.push(SitemapDefinition.getCurrentSiteMap().getFirstSitemapSubArea().url);
            return;
        }
        history.push(`${window.location.pathname}${window.location.search}${window.location.hash}`);
    }, []);

    return (
        <HistoryContext.Provider value={{
            currentPage: currentPage,
        }}>
            {children}
            {navigationDialogProps &&
                <NavigationPromptDialog {...navigationDialogProps} />
            }
            <MainLoading
                loadingText={AppModule.get().name}
                loadingImageSrc={AppModule.get().icon.value}
                isLoading={isLoading} />
        </HistoryContext.Provider>
    );
};