import { SitemapDefinition } from "@src/app/classes/definitions/SitemapDefinition";
import { State } from "./State";
import { ISessionPage, HistoryManager } from "./HistoryManager";
import { Dataset } from "@talxis/client-libraries";

enum PageType {
    Form,
    View,
    Other
}
export class Page {
    private _path: string;
    private _index: number;
    private _sitemapKey: string;
    private _entityName: string | null;
    private _state: State;
    private _previousPage: Page | null;
    private _nextPage: Page | null;
    private _dataset?: Dataset;
    public getFormContext = (): Xrm.FormContext | null => { return null; };

    constructor(path: string, index: number, previousPage?: Page, sessionPage?: ISessionPage) {
        //store every new page instance in array so we can easily search existing pages
        this._path = path;
        this._index = index;
        this._entityName = this._getEntityNameFromPath();
        HistoryManager.pages.push(this);
        if (sessionPage) {
            this._createFromSession(sessionPage);
            return;
        }
        this._state = new State(path);
        this._previousPage = previousPage;
        this._sitemapKey = this._getSitemapKey();
    }

    public get previousPage() {
        return this._previousPage;
    }
    public get nextPage() {
        return this._nextPage;
    }
    public get path() {
        return this._path;
    }
    public get index() {
        return this._index;
    }
    public get sitemapKey() {
        return this._sitemapKey;
    }
    public get entityName() {
        return this._entityName;
    }
    public get state() {
        return this._state;
    }

    public setNextPage(page: Page) {
        this._nextPage = page;
    }

    public setPreviousPage(page: Page) {
        this._previousPage = page;
    }

    public isDirty() {
        if (this.getFormContext()?.data.getIsDirty()) {
            return true;
        }
        if (this._dataset?.isDirty()) {
            return true;
        }
        return false;
    }

    public isFirst() {
        if (this.index === 0) {
            return true;
        }
    }

    public printPreviousPages() {
        const arr: string[] = [];
        const recursion = (arr: string[], previousPage: Page | null) => {
            if (!previousPage) {
                return;
            }
            arr.push(previousPage.path);
            recursion(arr, previousPage._previousPage);
        };
        recursion(arr, this);
        console.log(arr);
    }

    public setFormContext(value: Xrm.FormContext) {
        this.getFormContext = () => { return value; };
    }

    public setDataset(dataset: Dataset) {
        this._dataset = dataset;
    }
    public getDataset() {
        return this._dataset;
    }

    private _createFromSession(sessionPage: ISessionPage) {
        this._sitemapKey = sessionPage._sitemapKey;
        if (sessionPage._previousPage) {
            const existingPreviousPage = HistoryManager.pages.find(page => page.index === sessionPage._previousPage._index);
            this._previousPage = existingPreviousPage ?? new Page(sessionPage._previousPage._path, sessionPage._previousPage._index, null, sessionPage._previousPage);
        }
        if (sessionPage._nextPage) {
            const existingNextPage = HistoryManager.pages.find(page => page.index === sessionPage._nextPage._index);
            this._nextPage = existingNextPage ?? new Page(sessionPage._nextPage._path, sessionPage._nextPage._index, null, sessionPage._nextPage);
        }
        this._state = new State(this._path, sessionPage._state);
    }

    private _getEntityNameFromPath(): string {
        //this regex is used since '&' character breaks the data parameter parsing in both query-string and new URL
        const match = this.path.match(/\?data=(\{.*\})/);
        if (!match) {
            return null;
        }
        try {
            return JSON.parse(match[1]).entityName ?? null;
        }
        catch (err) {
            return null;
        }
    }
    private _getSitemapKey(): string | null {
        const sitemap = SitemapDefinition.getCurrentSiteMap();
        //this regex is used since '&' character breaks the data parameter parsing in both query-string and new URL
        const match = this.path.match(/\?data=(\{.*\})/);
        let strippedpath = this.path;
        if (match) {
            const { extraqs, ...strippedData } = JSON.parse(match[1]);
            const url = new URL(encodeURIComponent(this.path), this.path.startsWith("http") ? undefined : window.location.origin);
            url.searchParams.set('data', JSON.stringify(strippedData));
            strippedpath = decodeURIComponent(`${window.location.pathname}${url.search}`);
        }
        //here we should check the sitemap items, the steps are following:
        //1) check if there is a sitemap item that matches the route exactly
        const exactMatchSubArea = sitemap.getSitemapSubAreaByProp('url', strippedpath);
        if (exactMatchSubArea) {
            return strippedpath;
        }
        //2) if no entity name is present and no exact url match was found, do not highlight anything
        if (!this.entityName) {
            return null;
        }
        //3) if entity name is present and it is the same as the last page, return the same sitemap path as previous page (=> staying in current context)
        if (this._previousPage?.entityName === this.entityName) {
            return this._previousPage.sitemapKey ?? null;
        }
        //4) if the entity name differs and no exact match was found, try to highlight the first item that matches the entity name of current page;
        const subArea = sitemap.getSitemapSubAreaByProp('entity', this.entityName);
        if (subArea) {
            return subArea.url;
        }
        //5) no suitable sitemap item has been found => no higlighting
        return null;
    }
}