import { IButtonProps, ContextualMenuItemType, IContextualMenuItem } from "@fluentui/react";
import { useState, useRef } from "react";
import { Grid } from "../Grid";
import { ViewSelector } from "./ViewSelector";
import { sanitizeGuid } from "@src/app/Functions";
import { IViewIdName } from "@src/app/classes/definitions/ViewDefinition";

export const useViewSwitcher = (changeView: (query: string) => void): [
    IButtonProps,
    (grid: Grid) => Promise<void>,
    boolean,
    (value: boolean) => void
] => {
    const viewSelectorRef = useRef<ViewSelector>(null);
    const [viewPickerProps, setViewPickerProps] = useState<IButtonProps>(null);
    const [viewManagerDialogVisible, setViewManagerDialogVisible] = useState(false);

    const getViewItems = (viewNameAndIds: IViewIdName[]): IContextualMenuItem[] => {
        return viewNameAndIds.sort((a, b) => a.name.localeCompare(b.name)).map(x => {
            return {
                key: x.queryid,
                text: x.name,
                ['data-selected']: sanitizeGuid(viewSelectorRef.current.getCurrentView().id) === x.queryid,
                onClick: () => {
                    viewSelectorRef.current.setCurrentView({
                        entityType: viewSelectorRef.current.getCurrentView().entityType,
                        id: x.queryid,
                        name: x.name
                    });
                    refresh(x.name);
                }
            };
        });
    };

    const getContextualMenuItems = async (): Promise<IContextualMenuItem[]> => {
        const allViewNamesAndIds = await viewSelectorRef.current.getViewNamesAndIds();
        const systemViewNameAndIds = allViewNamesAndIds.filter(x => !x.isOwnedByCurrentUser);
        const userViewNameAndIds = allViewNamesAndIds.filter(x => x.isOwnedByCurrentUser);
        const result: IContextualMenuItem[] = [];

        if (userViewNameAndIds.length > 0) {
            result.push({
                key: 'userViewHeader',
                itemType: ContextualMenuItemType.Header,
                text: window.TALXIS.Portal.Translations.getLocalizedString("@pages/control/View/ViewSelector/MyViews"),
                iconProps: {
                    iconName: 'Contact'
                }
            },
                ...getViewItems(userViewNameAndIds),
            );
        }
        result.push({
            key: 'systemViewHeader',
            itemType: ContextualMenuItemType.Header,
            iconProps: {
                iconName: 'ViewList'
            },
            text: window.TALXIS.Portal.Translations.getLocalizedString("@pages/control/view/systemViews")
        },
            ...getViewItems(systemViewNameAndIds),
            ...(viewSelectorRef.current.grid.userQueriesEnabled ?
                [
                    {
                        key: 'systemViewDivider',
                        itemType: ContextualMenuItemType.Divider
                    },
                    {
                        key: 'saveNewView',
                        text: window.TALXIS.Portal.Translations.getLocalizedString("@pages/control/View/ViewSelector/SaveAsNew"),
                        iconProps: {
                            iconName: 'SaveAs'
                        },
                        onClick: () => { viewSelectorRef.current.saveNewView(); }
                    },
                    ...(await viewSelectorRef.current.isViewOwnedByCurrentUser()
                        ? [
                            {
                                key: 'saveExistingViewView',
                                text: window.TALXIS.Portal.Translations.getLocalizedString("@pages/control/View/ViewSelector/SaveExisting"),
                                iconProps: {
                                    iconName: 'Save'
                                },
                                onClick: () => { viewSelectorRef.current.saveExistingView(); }
                            }
                        ]
                        : []),
                    {
                        key: 'manageViews',
                        text: window.TALXIS.Portal.Translations.getLocalizedString("@pages/control/View/ViewSelector/ManageViews"),
                        iconProps: {
                            iconName: 'Settings'
                        },
                        onClick: () => setViewManagerDialogVisible(true)
                    }
                ] : []),

        );
        return result;
    };

    const getButtonProps = async (): Promise<IButtonProps> => {
        return {
            text: await viewSelectorRef.current.getTitle(),
            menuProps: {
                items: await getContextualMenuItems()
            }
        };
    };

    const refresh = async (text?: string) => {
        if (!viewSelectorRef.current.isVisible() || text === "") {
            setViewPickerProps(null);
            return;
        }
        setViewPickerProps(await getButtonProps());
    };

    const init = async (grid: Grid) => {
        viewSelectorRef.current = grid.viewSelector;
        grid.viewSelector.init(refresh, changeView);
    };

    return [viewPickerProps, init, viewManagerDialogVisible, (value) => setViewManagerDialogVisible(value)];
};
