import * as ReactDOM from 'react-dom';
import * as React from 'react';
import { Grid as GridComponent } from "@talxis/base-controls";
import { IGrid } from "@talxis/base-controls";
import { EntityDefinition } from '@src/app/classes/definitions/EntityDefinition';
import { AggregationButtonPlacementTypes, View as ViewComponent } from './components/View/View'; //legacy grid
import { Liquid } from 'liquidjs';
import { IDataset } from '@talxis/client-libraries';

export interface IInputs {
    EnableEditing: ComponentFramework.PropertyTypes.EnumProperty<"true" | "false">;
    EnablePagination: ComponentFramework.PropertyTypes.EnumProperty<"true" | "false">;
    EnableFiltering: ComponentFramework.PropertyTypes.EnumProperty<"true" | "false">;
    EnableSorting: ComponentFramework.PropertyTypes.EnumProperty<"true" | "false">;
    EnableNavigation: ComponentFramework.PropertyTypes.EnumProperty<"true" | "false">;
    EnableChangeEditor: ComponentFramework.PropertyTypes.EnumProperty<"true" | "false">;
    RowHeight: ComponentFramework.PropertyTypes.WholeNumberProperty;
    EnableOptionSetColors: ComponentFramework.PropertyTypes.EnumProperty<"true" | "false">;
    Height: ComponentFramework.PropertyTypes.StringProperty;
    DisableNewGrid: ComponentFramework.PropertyTypes.EnumProperty<"true" | "false">;
    SelectableRows: ComponentFramework.PropertyTypes.EnumProperty<"none" | "single" | "multiple">;
    Columns: ComponentFramework.PropertyTypes.StringProperty;
    InlineRibbonButtonIds: ComponentFramework.PropertyTypes.StringProperty; //legacy grid
    Grid: IDataset
    TargetEntityType: ComponentFramework.PropertyTypes.StringProperty; //legacy grid
    EnableGroupBy: ComponentFramework.PropertyTypes.StringProperty; //legacy grid
    AggregateObjectArray: ComponentFramework.PropertyTypes.StringProperty; //legacy grid
    GroupByAttributes: ComponentFramework.PropertyTypes.StringProperty; //legacy grid
    AggregationButtonPlacement: ComponentFramework.PropertyTypes.EnumProperty<AggregationButtonPlacementTypes>; //legacy grid
}
export interface IOutputs {
}

export class View implements ComponentFramework.StandardControl<IInputs, IOutputs> {
    private _container: HTMLDivElement;
    private _state: ComponentFramework.Dictionary;
    private _enableNavigation: boolean; //legacy grid
    private _entityDefinition: ComponentFramework.PropertyHelper.EntityMetadata; //legacy grid
    private _context: ComponentFramework.Context<IInputs>; //legacy grid
    private _liquid: Liquid = new Liquid(); //legacy grid
    private _disableNewGrid = false;
    private _lastUsedColumns: string | null = null;

    /**
     * Used to initialize the control instance. Controls can kick off remote server calls and other initialization actions here.
     * Data-set values are not initialized here, use updateView.
     * @param context The entire property bag available to control via Context Object; It contains values as set up by the customizer mapped to property names defined in the manifest, as well as utility functions.
     * @param notifyOutputChanged A callback method to alert the framework that the control has new outputs ready to be retrieved asynchronously.
     * @param state A piece of data that persists in one session for a single user. Can be set at any point in a controls life cycle by calling 'setControlState' in the Mode interface.
     * @param container If a control is marked control-type='standard', it will receive an empty div element within which it can render its content.
     */
    public init(context: ComponentFramework.Context<IInputs, any>, notifyOutputChanged: () => void, state: ComponentFramework.Dictionary, container: HTMLDivElement): void {
        this._container = container;
        this._state = state;
        this._context = context;
        this._lastUsedColumns = context.parameters.Columns?.raw;
        //fallback to legacy grid  if explicitly requested or we are in grouped grid
        if (context.parameters.DisableNewGrid?.raw === 'true' || context.parameters.EnableGroupBy?.raw === "true") {
            this._context = context;
            this._disableNewGrid = true;
            this._container = document.createElement("div");
            container.appendChild(this._container);
            this._enableNavigation = context.parameters.EnableNavigation.raw !== "false";
            if (this._enableNavigation) {
                this._getEntityDefinition();
            }
        }
        notifyOutputChanged();
    }

    /**
     * Called when any value in the property bag has changed. This includes field values, data-sets, global values such as container height and width, offline status, control metadata values such as label, visible, etc.
     * @param context The entire property bag available to control via Context Object; It contains values as set up by the customizer mapped to names defined in the manifest, as well as utility functions
     */
    public updateView(context: ComponentFramework.Context<IInputs, any>): void {
        if (this._disableNewGrid) {
            this._context = context;
            if (!this._enableNavigation || (this._entityDefinition)) {
                ReactDOM.render(React.createElement(ViewComponent, {
                    dataset: context.parameters.Grid,
                    resources: context.resources,
                    primaryName: this._enableNavigation && this._entityDefinition.PrimaryNameAttribute,
                    onOpenDatasetItem: (entityReference) => this._enableNavigation && context.parameters.Grid.openDatasetItem(entityReference),
                    //@ts-ignore - part of documentation, not part of types
                    pageNumber: context.parameters.Grid.paging.pageNumber,
                    enableGroupBy: context.parameters.EnableGroupBy.raw === "true",
                    selectableRows: context.parameters.SelectableRows.raw,
                    enableFiltering: context.parameters.EnableFiltering.raw !== "false",
                    enableSorting: context.parameters.EnableSorting.raw !== "false",
                    aggregateObjectArray: JSON.parse(context.parameters.AggregateObjectArray?.raw),
                    groupByAttributes: context.parameters.GroupByAttributes?.raw ? context.parameters.GroupByAttributes?.raw.replaceAll(' ', '').split(',') : null,
                    aggregationButtonPlacement: context.parameters.AggregationButtonPlacement?.raw,
                    getTranslation: this.getTranslation.bind(this),
                    factory: context.factory,
                    formatting: context.formatting,
                    state: this._state,
                    mode: context.mode,
                    fireEvent: (eventName, object) => {
                        // @ts-ignore - fireEvent is not part of PCF types at the moment
                        context.factory.fireEvent(eventName, object);
                    }
                }), this._container);
            }
            return;
        }
        const parameters = context.parameters;
        this._refreshOnChange(this._lastUsedColumns, parameters.Columns?.raw, () => parameters.Grid.setColumns(this._getColumns()));
        this._lastUsedColumns = parameters.Columns?.raw;
        ReactDOM.render(React.createElement(GridComponent, {
            context: context as any,
            state: this._state,
            parameters: {
                Grid: parameters.Grid,
                EnableEditing: {
                    raw: parameters.EnableEditing?.raw === 'true'
                },
                EnablePagination: {
                    raw: parameters.EnablePagination?.raw === 'false' ? false : true
                },
                EnableFiltering: {
                    raw: parameters.EnableFiltering?.raw === 'false' ? false : true
                },
                EnableSorting: {
                    raw: parameters.EnableSorting?.raw === 'false' ? false : true
                },
                EnableNavigation: {
                    raw: parameters.EnableNavigation?.raw === 'false' ? false : true
                },
                EnableOptionSetColors: {
                    raw: parameters.EnableOptionSetColors?.raw === 'true'
                },
                InlineRibbonButtonIds: {
                    raw: parameters.InlineRibbonButtonIds?.raw
                },
                Height: {
                    raw: parameters.Height?.raw
                },
                RowHeight: {
                    raw: parameters.RowHeight?.raw
                },
                EnableChangeEditor: {
                    raw: parameters.EnableChangeEditor?.raw === 'false' ? false : true
                },
                SelectableRows: this._getSelectableRowsParameter(parameters.SelectableRows)
            }
        } as IGrid), this._container);
    }

    /**
     * It is called by the framework prior to a control receiving new data.
     * @returns an object based on nomenclature defined in manifest, expecting object[s] for property marked as "bound" or "output"
     */
    public getOutputs(): IOutputs {
        return {
            DatasetControl: this._context.parameters.Grid
        };
    }

    /**
     * Called when the control is to be removed from the DOM tree. Controls should use this call for cleanup.
     * i.e. cancelling any pending remote calls, removing listeners, etc.
     */
    public destroy(): void {
        if (!this._container) {
            return;
        }
        ReactDOM.unmountComponentAtNode(this._container);
    }
    public getTranslation(string: string, variables: {} = {}): string {
        const localString = this._context.resources.getString(string);
        return Object.keys(variables).length > 0 ? this._liquid.parseAndRenderSync(localString, variables) : localString;
    }
    private _getSelectableRowsParameter(selectableRows?: ComponentFramework.PropertyTypes.EnumProperty<"none" | "single" | "multiple">) {
        switch (selectableRows?.raw) {
            //@ts-ignore - legacy binding support
            case 'true': {
                return {
                    ...selectableRows,
                    raw: 'multiple'
                };
            }
            //@ts-ignore - legacy binding support
            case 'false': {
                return {
                    ...selectableRows,
                    raw: 'none'
                };
            }
        }
        return selectableRows;
    }
    //legacy grid
    private async _getEntityDefinition() {
        this._entityDefinition = await EntityDefinition.getAsync(this._context.parameters.TargetEntityType.raw);
        this.updateView(this._context);
    }
    private _refreshOnChange(originalValue: string | null, newValue: string | null, beforeRefreshCallback: () => void) {
        if (originalValue != newValue) {
            beforeRefreshCallback();
            this._context.parameters.Grid.refresh();
        }
    }
    private _getColumns() {
        try {
            if (this._context.parameters.Columns?.raw) {
                return JSON.parse((this._context.parameters.Columns?.raw));
            }
            else {
                return [];
            }
        }
        catch (err) {
            return this._context.parameters.Grid.columns;
        }
    }
}
