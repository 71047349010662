import React from 'react';
import { DialogFooter, Icon, PrimaryButton } from '@fluentui/react';
import { Text } from '@fluentui/react/lib/Text';
import { IAlertDialogProps } from './interfaces';
import Dialog from '../Dialog';
import { rootStyles } from './Styles';
import { defaultProps } from '../Dialog/Constants';

const AlertDialog: React.FC<IAlertDialogProps> = (props) => {
    return (
        <Dialog
            {...props}
            modalProps={{
                ...props.modalProps,
                className: `${props.modalProps.className} ${rootStyles}`
            }}
            dialogContentProps={{
                ...props.dialogContentProps,
                title: <Text variant='xLarge'>{props.dialogContentProps.title}</Text>
            }}>
            {props.hidden !== undefined &&
                <DialogFooter>
                    <PrimaryButton
                        text={props.dialogContentProps.confirmButtonLabel ?
                            props.dialogContentProps.confirmButtonLabel : 'OK'}
                        onClick={props.onDismiss} />
                </DialogFooter>
            }
        </Dialog>
    );
};
export default AlertDialog;
AlertDialog.defaultProps = defaultProps;