import { Grid } from "../Grid";
import { ControlLoader } from "@src/app/classes/loaders/ControlLoader";
import { Context } from "@src/ComponentFramework/PropertyClasses/Context";
import { FormControlType } from "../../native/Form/interfaces/enums";
import { FetchXmlBuilder, FetchXmlFactory } from "@talxis/client-libraries/dist/utils/fetch-xml";

export class QueryBuilder {
    private _grid: Grid;
    private _modifiedFetchXml: string;
    private _mounted: boolean;
    private _refreshCallback: () => void;
    private _pcfContext: any;
    private _container: HTMLDivElement;
    private _showUserFilters: boolean;

    constructor(grid: Grid, refreshCallback: () => void, showUserFilters: boolean) {
        this._grid = grid;
        this._showUserFilters = showUserFilters;
        this._refreshCallback = refreshCallback;
    }
    public async init(container: HTMLDivElement) {
        this._container = container;
        const definition = await ControlLoader.getAsync('TALXIS.PCF.QueryBuilder');
        await definition.load();
        const registration = await definition.registration;
        this._pcfContext = new Context({
            bindings: {},
            id: "",
            datafieldname: null,
            type: FormControlType.Field,
            visible: true,
            isUnbound: true,
            isRequired: false,
            name: 'TALXIS.PCF.QueryBuilder',
        }, registration, null, null, () => { });
        const component = this._pcfContext.factory.createComponent("talxis_TALXIS.PCF.QueryBuilder", `queryBuilder_user_${this._showUserFilters}`, this._getProperties());
        this._pcfContext.factory.bindDOMElement(component, container);
        this._mounted = true;
    }
    public isDirty() {
        const factory = new FetchXmlFactory(this._modifiedFetchXml);
        return !factory.isEqual(this._grid.getDataSource());
    }
    public save() {
        this._grid.setDataSource(this._modifiedFetchXml);
        this._grid.refresh();
    }
    public destroy() {
        if (!this._mounted) {
            return false;
        }
        this._pcfContext.factory.unbindDOMComponent(`queryBuilder_user_${this._showUserFilters}`);
        this._mounted = false;
    }

    public reset() {
        this._modifiedFetchXml = this._grid.initialFetchXml;
        this._refreshCallback();
        this.destroy();
        this.init(this._container);
    }
    public download() {
        const element = document.createElement("a");
        const file = new Blob([this._grid.getDataSource()], { type: 'application/xml' });
        element.href = URL.createObjectURL(file);
        element.download = "FetchXML";
        element.click();
    }
    public deleteAllFilters() {
        alert('deleting filters');
    }

    private _getInputFetchXml() {
        if (!this._showUserFilters) {
            return this._modifiedFetchXml ?? this._grid.getDataSource();
        }
        const factory = new FetchXmlFactory(new FetchXmlBuilder.fetch(
            new FetchXmlBuilder.entity(this._grid.getTargetEntityType())
        ).toXml());
        factory.setFilter([this._grid.filtering.getFilter()]);
        const result = factory.build();
        return result;
    }

    private _getProperties() {
        const properties: any = {
            controlstates: {
                // This is the only implemented controlState parameter
                isControlDisabled: this._showUserFilters,
            },
            parameters: {
                value: {
                    Static: false,
                    Primary: true,
                    Type: "SingleLine.Text",
                    Value: this._getInputFetchXml(),
                    Usage: 0, // 0 - input, 1 - bound, 3 - false bound
                    Callback: (value: any) => {
                        if (this._showUserFilters) {
                            return;
                        }
                        this._modifiedFetchXml = value;
                        this._refreshCallback();
                    }
                },
                queryEntity: {
                    Static: true,
                    Value: this._grid.getTargetEntityType()
                }
            },
            // Optionally you can specify custom event listeners to pass complex objects back to parent
        };
        return properties;
    }
}