import React from 'react';
import { defaultProps } from './Constants';
import { getRootStyles } from './Styles';
import { Spinner } from '@talxis/react-components';
import { ISpinnerProps } from '@fluentui/react';

const Loading: React.FC<ISpinnerProps> = (props) => {
    return (
        <Spinner
            {...props}
            className={`${getRootStyles()} ${props.className}`}
        />
    );
};
export default Loading;
Loading.defaultProps = defaultProps;