import { Grid } from "../Grid";
import { ViewDefinition } from "@src/app/classes/definitions/ViewDefinition";
import { history } from '@providers/HistoryProvider/HistoryProvider';
import { Attribute, FetchXml, FetchXmlFactory, IColumn } from "@talxis/client-libraries";
import { ILayout as IGridLayout } from "@talxis/client-libraries";
import { XrmFormContext } from "../../native/Form/Form";

export class ViewSelector implements Xrm.Controls.ViewSelector {
    private _grid: Grid;
    private _refreshCallback: () => void = () => { };
    private _changeViewCallback: (queryId: string) => void;

    constructor(grid: Grid) {
        this._grid = grid;
    }
    public get grid() {
        return this._grid;
    }
    public init(refreshCallback: () => void, changeViewCallback: (queryId: string) => void) {
        this._refreshCallback = refreshCallback;
        this._changeViewCallback = changeViewCallback;
        this.refresh();
    }
    public getCurrentView(): Xrm.LookupValue {
        return {
            entityType: this._grid.getTargetEntityType(),
            id: this._grid.getViewId(),
            name: this._grid.getTitle()
        };
    }
    public isVisible(): boolean {
        return this._grid.cdsOptions.displayViewSelector;
    }
    public setCurrentView(viewSelectorItem: Xrm.LookupValue): void {
        if (this._grid.isHomePageGrid) {
            const data = {
                entityName: viewSelectorItem.entityType,
                viewId: `${viewSelectorItem.id}`,
            };
            const url = `${window.location.pathname}?data=${JSON.stringify(data)}`;
            history.push(url);
            return;
        }
        //this call back should result in the entire grid being reinitialized
        this._changeViewCallback(viewSelectorItem.id);
    }
    public async getViewNamesAndIds() {
        return ViewDefinition.getViewNamesAndIds(this._grid.getTargetEntityType(), { includeUserQueries: this.grid.userQueriesEnabled });
    }
    public async refresh(clearCache?: boolean) {
        if (clearCache) {
            ViewDefinition.clearEntityViewNameIdCache(this._grid.getTargetEntityType());
        }
        this._refreshCallback();
    }
    public async getTitle(): Promise<string> {
        return (await this.getViewNamesAndIds()).find(x => x.queryid === this._grid.getViewId())?.name ?? this._grid.getTitle();
    }
    public async saveNewView() {
        //@ts-ignore - typings
        const result = await window.Xrm.Navigation._openDialogInternal('talxis_savenewuserquery', {
            width: 400
        }, {},
            (formContext: XrmFormContext, formData: { [key: string]: any }) => {
                if (!formData?.['talxis_name']) {
                    const attr = formContext.getAttribute('talxis_name');
                    attr.setValue('');
                    attr.setIsValid(false);
                    return false;
                }
                return true;
            });
        if (!result) {
            return;
        }
        const name = result.parameters['talxis_name'];
        const description = result.parameters['talxis_description'];

        window.Xrm.Utility.showProgressIndicator(window.TALXIS.Portal.Translations.getLocalizedString('@definitions/RibbonDefinition/Saving'));
        const userqueryid = `00001111${crypto.randomUUID().substring(8)}`;
        const returnedTypeCode = this._grid.getTargetEntityType();
        const layoutJson = await this.getLayoutJSONFromColumns(this._grid.columns);
        const fetch = new FetchXml(this._getCurrentFetchXml()).builder;
        fetch.savedqueryid = null;
        fetch.userqueryid = userqueryid;

        await window.Xrm.WebApi.createRecord('talxis_userquery', {
            'talxis_userqueryid': userqueryid,
            'talxis_fetchxml': fetch.toXml(),
            'talxis_layoutjson': layoutJson,
            'talxis_name': name,
            'talxis_description': description,
            'talxis_returnedtypecode': returnedTypeCode,
        });
        ViewDefinition.clearEntityViewNameIdCache(returnedTypeCode);
        this.setCurrentView({
            entityType: returnedTypeCode,
            id: userqueryid
        });
        window.Xrm.Utility.closeProgressIndicator();
    }
    public async saveExistingView(doNotShowAlerts?: boolean) {
        if (!doNotShowAlerts) {
            window.Xrm.Utility.showProgressIndicator(window.TALXIS.Portal.Translations.getLocalizedString('@definitions/RibbonDefinition/Saving'));
        }
        const layoutJson = await this.getLayoutJSONFromColumns(this._grid.columns);
        window.Xrm.WebApi.updateRecord('talxis_userquery', this.getCurrentView().id, {
            'talxis_fetchxml': this._getCurrentFetchXml(),
            'talxis_layoutjson': layoutJson,
        });
        ViewDefinition.clearViewFromCache(this.getCurrentView().id);
        if (!doNotShowAlerts) {
            window.Xrm.Utility.closeProgressIndicator();
            window.Xrm.Navigation.openAlertDialog({
                text: window.TALXIS.Portal.Translations.getLocalizedString('@pages/control/View/ViewSelector/SaveExistingSuccess')
            });
        }
    }
    public async isViewOwnedByCurrentUser(): Promise<boolean> {
        const viewIdNames = await this.getViewNamesAndIds();
        return viewIdNames.find(x => x.queryid === this.getCurrentView().id)?.isOwnedByCurrentUser;
    }
    public async getLayoutJSONFromColumns(columns: IColumn[]) {
        const primaryIdAttribute = (await window.Xrm.Utility.getEntityMetadata(this._grid.getTargetEntityType(), [])).PrimaryIdAttribute;
        const layout: IGridLayout = {
            Icon: false,
            CustomControlDescriptions: [],
            Preview: true,
            IconRenderer: "",
            Jump: "name",
            Name: "resultset",
            Object: 1,
            Select: true,
            Rows: [{
                Id: primaryIdAttribute,
                LayoutStyle: "",
                MultiObjectIdField: "",
                Name: "result",
                Cells: columns.map(column => {
                    return {
                        ImageProviderFunctionName: "",
                        ImageProviderWebresource: "",
                        Name: column.name,
                        Width: column.visualSizeFactor,
                        IsHidden: !!column.isHidden,
                        RelatedEntityName: (() => {
                            const linkedEntityAlias = Attribute.GetLinkedEntityAlias(column.name);
                            if (!linkedEntityAlias) {
                                return '';
                            }
                            return this._grid.linking.getLinkedEntities().find(linkedEntity => linkedEntity.alias === linkedEntityAlias).name;
                        })()
                    };
                }),

            }]
        };
        return JSON.stringify(layout);
    }

    private _getCurrentFetchXml(): string {
        return new FetchXmlFactory(this._grid.getDataSource())
            .setLinking(this._grid.linking.getLinkedEntities())
            .setColumns(this._grid.columns)
            .setSorting(this._grid.sorting)
            .setFilter([this._grid.filtering.getFilter()])
            .build();
    }

}