import { IDropdownOptionFilterBy } from "./interfaces/IDropdownOptionsFilterBy";
import { DataType } from "@ComponentFramework/interfaces/DataType";

// https://docs.microsoft.com/en-us/dotnet/api/microsoft.xrm.sdk.query.conditionoperator?view=dataverse-sdk-latest
export enum ConditionOperator {
    Unknown = -2,
    Equal = 0,
    DoesNotEqual = 1,
    GreaterThan = 2,
    LessThan = 3,
    GreaterThanOrEqual = 4,
    LessThanOrEqual = 5,
    Like = 6,
    NotLike = 7,
    DoesNotContainData = 12,
    NotNull = 13,
    Yesterday = 14,
    Today = 15,
    Tomorrow = 16,
    Last7Days = 17,
    Next7Days = 18,
    LastWeek = 19,
    ThisWeek = 20,
    LastMonth = 22,
    ThisMonth = 23,
    On = 25,
    OnOrBefore = 26,
    OnOrAfter = 27,
    LastYear = 28,
    ThisYear = 29,
    LastXDays = 33,
    NextXDays = 34,
    LastXMonths = 37,
    NextXMonths = 38,
    ContainsData = 49,
    ContainValues = 87,
    DoesNotContainValues = 88,
    BeginsWith = 54,
    DoesNotBeginWith = 55,
    EndsWith = 56,
    DoesNotEndWith = 57,
    In = 8,
    NotIn = 9
}

class TranslatedDropdownOptionFilterBy implements IDropdownOptionFilterBy {
    public key: string;
    public enabledDataTypes: string[];
    public conditionOperator: ConditionOperator;
    public text: string;

    constructor(key: string, enabledDataTypes: string[], conditionOperator: ConditionOperator, getTranslation: (value: string) => string) {
        this.key = key;
        this.enabledDataTypes = enabledDataTypes;
        this.conditionOperator = conditionOperator;
        this.text = getTranslation(`filterByFilter-${this.key as string}`);
    }
}

let translatedFilterByOptions: IDropdownOptionFilterBy[] = [];

export const filterByOptions: (getTranslation: (value: string) => string) => IDropdownOptionFilterBy[] = (getTranslation) => {
    if (translatedFilterByOptions.length > 0) return translatedFilterByOptions;
    translatedFilterByOptions = [
        new TranslatedDropdownOptionFilterBy("equals", [DataType.SingleLineText, DataType.Decimal, DataType.Currency, DataType.FP, DataType.WholeNone, DataType.Currency, DataType.OptionSet, DataType.TwoOptions], ConditionOperator.Equal, getTranslation),
        new TranslatedDropdownOptionFilterBy("doesnotequal", [DataType.SingleLineText, DataType.OptionSet, DataType.TwoOptions, DataType.Decimal, DataType.Currency, DataType.FP, DataType.WholeNone, DataType.Currency], ConditionOperator.DoesNotEqual, getTranslation),
        new TranslatedDropdownOptionFilterBy("contains", [DataType.SingleLineText], ConditionOperator.Like, getTranslation),
        new TranslatedDropdownOptionFilterBy("notcontains", [DataType.SingleLineText], ConditionOperator.NotLike, getTranslation),

        // For MultiSelectOptionSet we are using In and NotIn instead of Equals
        new TranslatedDropdownOptionFilterBy("equals", [DataType.MultiSelectOptionSet, DataType.MultiSelectPicklist, DataType.LookupSimple, DataType.LookupCustomer, DataType.LookupOwner], ConditionOperator.In, getTranslation),
        new TranslatedDropdownOptionFilterBy("doesnotequal", [DataType.MultiSelectOptionSet, DataType.MultiSelectPicklist, DataType.LookupSimple, DataType.LookupCustomer, DataType.LookupOwner], ConditionOperator.NotIn, getTranslation),

        new TranslatedDropdownOptionFilterBy("beginswith", [DataType.SingleLineText], ConditionOperator.BeginsWith, getTranslation),
        new TranslatedDropdownOptionFilterBy("doesnotbeginwith", [DataType.SingleLineText], ConditionOperator.DoesNotBeginWith, getTranslation),
        new TranslatedDropdownOptionFilterBy("endswith", [DataType.SingleLineText], ConditionOperator.EndsWith, getTranslation),
        new TranslatedDropdownOptionFilterBy("doesnotendwith", [DataType.SingleLineText], ConditionOperator.DoesNotEndWith, getTranslation),
        new TranslatedDropdownOptionFilterBy("onorbefore", [DataType.DateAndTimeDateAndTime, DataType.DateAndTimeDateOnly], ConditionOperator.OnOrBefore, getTranslation),
        new TranslatedDropdownOptionFilterBy("onorafter", [DataType.DateAndTimeDateAndTime, DataType.DateAndTimeDateOnly], ConditionOperator.OnOrAfter, getTranslation),
        new TranslatedDropdownOptionFilterBy("on", [DataType.DateAndTimeDateAndTime, DataType.DateAndTimeDateOnly], ConditionOperator.On, getTranslation),
        new TranslatedDropdownOptionFilterBy("tomorrow", [DataType.DateAndTimeDateAndTime, DataType.DateAndTimeDateOnly], ConditionOperator.Tomorrow, getTranslation),
        new TranslatedDropdownOptionFilterBy("today", [DataType.DateAndTimeDateAndTime, DataType.DateAndTimeDateOnly], ConditionOperator.Today, getTranslation),
        new TranslatedDropdownOptionFilterBy("yesterday", [DataType.DateAndTimeDateAndTime, DataType.DateAndTimeDateOnly], ConditionOperator.Yesterday, getTranslation),
        new TranslatedDropdownOptionFilterBy("thisweek", [DataType.DateAndTimeDateAndTime, DataType.DateAndTimeDateOnly], ConditionOperator.ThisWeek, getTranslation),
        new TranslatedDropdownOptionFilterBy("thismonth", [DataType.DateAndTimeDateAndTime, DataType.DateAndTimeDateOnly], ConditionOperator.ThisMonth, getTranslation),
        new TranslatedDropdownOptionFilterBy("thisyear", [DataType.DateAndTimeDateAndTime, DataType.DateAndTimeDateOnly], ConditionOperator.ThisYear, getTranslation),
        new TranslatedDropdownOptionFilterBy("lastweek", [DataType.DateAndTimeDateAndTime, DataType.DateAndTimeDateOnly], ConditionOperator.LastWeek, getTranslation),
        new TranslatedDropdownOptionFilterBy("lastsevendays", [DataType.DateAndTimeDateAndTime, DataType.DateAndTimeDateOnly], ConditionOperator.Last7Days, getTranslation),
        new TranslatedDropdownOptionFilterBy("nextsevendays", [DataType.DateAndTimeDateAndTime, DataType.DateAndTimeDateOnly], ConditionOperator.Next7Days, getTranslation),
        new TranslatedDropdownOptionFilterBy("lastmonth", [DataType.DateAndTimeDateAndTime, DataType.DateAndTimeDateOnly], ConditionOperator.LastMonth, getTranslation),
        new TranslatedDropdownOptionFilterBy("lastyear", [DataType.DateAndTimeDateAndTime, DataType.DateAndTimeDateOnly], ConditionOperator.LastYear, getTranslation),
        // TODO: This is not supported yet until we properly handle number formatting in PCF
        // new TranslatedDropdownOptionFilterBy("lastxdays", [DataType.DateAndTimeDateAndTime, DataType.DateAndTimeDateOnly], ConditionOperator.LastXDays, getTranslation),
        // new TranslatedDropdownOptionFilterBy("nextxdays", [DataType.DateAndTimeDateAndTime, DataType.DateAndTimeDateOnly], ConditionOperator.NextXDays, getTranslation),
        // new TranslatedDropdownOptionFilterBy("lastxmonths", [DataType.DateAndTimeDateAndTime, DataType.DateAndTimeDateOnly], ConditionOperator.LastXMonths, getTranslation),
        // new TranslatedDropdownOptionFilterBy("nextxmonths", [DataType.DateAndTimeDateAndTime, DataType.DateAndTimeDateOnly], ConditionOperator.NextXMonths, getTranslation),
        new TranslatedDropdownOptionFilterBy("containvalues", [DataType.MultiSelectOptionSet, DataType.MultiSelectPicklist], ConditionOperator.ContainValues, getTranslation),
        new TranslatedDropdownOptionFilterBy("doesnotcontainvalues", [DataType.MultiSelectOptionSet, DataType.MultiSelectPicklist], ConditionOperator.DoesNotContainValues, getTranslation),
        new TranslatedDropdownOptionFilterBy("greaterthan", [DataType.Decimal, DataType.Currency, DataType.FP, DataType.WholeNone, DataType.Currency], ConditionOperator.GreaterThan, getTranslation),
        new TranslatedDropdownOptionFilterBy("lessthan", [DataType.Decimal, DataType.Currency, DataType.FP, DataType.WholeNone, DataType.Currency], ConditionOperator.LessThan, getTranslation),
        new TranslatedDropdownOptionFilterBy("greaterthanorequalto", [DataType.Decimal, DataType.Currency, DataType.FP, DataType.WholeNone, DataType.Currency], ConditionOperator.GreaterThanOrEqual, getTranslation),
        new TranslatedDropdownOptionFilterBy("lessthanorequalto", [DataType.Decimal, DataType.Currency, DataType.FP, DataType.WholeNone, DataType.Currency], ConditionOperator.LessThanOrEqual, getTranslation),
        new TranslatedDropdownOptionFilterBy("containsdata", [DataType.SingleLineText, DataType.DateAndTimeDateAndTime, DataType.DateAndTimeDateOnly, DataType.OptionSet, DataType.TwoOptions, DataType.MultiSelectOptionSet, DataType.Decimal, DataType.Currency, DataType.FP, DataType.WholeNone, DataType.Currency, DataType.LookupSimple, DataType.LookupCustomer, DataType.LookupOwner], ConditionOperator.ContainsData, getTranslation),
        new TranslatedDropdownOptionFilterBy("doesnotcontaindata", [DataType.SingleLineText, DataType.DateAndTimeDateAndTime, DataType.DateAndTimeDateOnly, DataType.OptionSet, DataType.TwoOptions, DataType.MultiSelectOptionSet, DataType.Decimal, DataType.Currency, DataType.FP, DataType.WholeNone, DataType.Currency, DataType.LookupSimple, DataType.LookupCustomer, DataType.LookupOwner], ConditionOperator.DoesNotContainData, getTranslation),
    ];
    return translatedFilterByOptions;
};

export const filterByHiddenTypes = [
    ConditionOperator.DoesNotContainData,
    ConditionOperator.Yesterday,
    ConditionOperator.Today,
    ConditionOperator.Tomorrow,
    ConditionOperator.Last7Days,
    ConditionOperator.Next7Days,
    ConditionOperator.LastWeek,
    ConditionOperator.ThisWeek,
    ConditionOperator.LastMonth,
    ConditionOperator.ThisMonth,
    ConditionOperator.LastYear,
    ConditionOperator.ThisYear,
    ConditionOperator.ContainsData,
];

export const filterByInputFields = [
    ConditionOperator.Equal,
    ConditionOperator.DoesNotEqual,
    ConditionOperator.GreaterThan,
    ConditionOperator.LessThan,
    ConditionOperator.GreaterThanOrEqual,
    ConditionOperator.LessThanOrEqual,
    ConditionOperator.Like,
    ConditionOperator.LastXDays,
    ConditionOperator.NextXDays,
    ConditionOperator.LastXMonths,
    ConditionOperator.NextXMonths
];

export const filterByDatePickerFields = [
    ConditionOperator.On,
    ConditionOperator.OnOrBefore,
    ConditionOperator.OnOrAfter
];