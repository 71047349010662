import Dialog from "@src/components/dialogs/Dialog";
import { useEffect, useMemo, useRef, useState } from "react";
import { ViewManager } from "../ViewManager";
import { Grid } from "../../Grid";
import { DatasetControl } from "@talxis/base-controls";
import { Context } from "@src/ComponentFramework/PropertyClasses/Context";
import { ThemeDefinition } from "@src/app/classes/definitions/ThemeDefinition";
import { DefaultButton, DialogFooter, IDialogProps, PrimaryButton } from "@fluentui/react";
import { getViewManagerDialogStyles } from "./styles";
import React from "react";

interface IViewManagerDialog extends IDialogProps {
    grid: Grid
}

const MemoizedDatasetControl = React.memo(DatasetControl);

export const ViewManagerDialog = (props: IViewManagerDialog) => {

    const [viewManagerInitialized, setViewManagerInitialized] = useState(false);
    const viewManager = useMemo(() => new ViewManager(props.grid, () => setViewManagerInitialized(true)), []);
    const [shouldRerender, setShouldRerender] = useState(false);
    const styles = useMemo(() => getViewManagerDialogStyles(), []);

    const onInit = () => {
        viewManager.dataset.addEventListener('onRecordColumnValueChanged', () => {
            setShouldRerender((prev) => !prev);
        });
        setViewManagerInitialized(true);
    };

    useEffect(() => {
        onInit();
    }, [viewManagerInitialized]);

    return <Dialog
        dialogContentProps={{
            title: window.TALXIS.Portal.Translations.getLocalizedString("@pages/control/View/ViewSelector/ManageViews"),
            className: styles.dialogContent
        }}
        modalProps={{
            isBlocking: true,
            layerProps: {
                eventBubblingEnabled: true
            }
        }}
        height={600}
        width={600}
        maxWidth={600}
        hidden={false}
        {...props}
    >
        {viewManagerInitialized &&
            <MemoizedDatasetControl
                context={Context.getBasicContext(ThemeDefinition.get().controlV9)}
                parameters={{
                    Grid: viewManager.dataset,
                    EnableEditing: {
                        raw: true
                    },
                    EnableNavigation: {
                        raw: false
                    },
                    EnablePagination: {
                        raw: false
                    },
                    Height: {
                        raw: '100%'
                    },
                    SelectableRows: {
                        raw: 'none'
                    },
                    EnableChangeEditor: {
                        raw: false
                    },
                }} />
        }
        <DialogFooter>
            {viewManagerInitialized &&
                <div className={styles.dialogFooter}>
                    <PrimaryButton
                        disabled={!viewManager.dataset.isDirty()}
                        text={window.TALXIS.Portal.Translations.getLocalizedString('@definitions/RibbonDefinition/Mscrm.SavePrimary')}
                        onClick={async () => {
                            await viewManager.saveChanges();
                            props.onDismiss?.();
                        }}
                    />
                    <DefaultButton
                        text={window.TALXIS.Portal.Translations.getLocalizedString('components/navigation/panels/LookupPanel/Cancel')}
                        onClick={() => props.onDismiss?.()} />
                </div>
            }
        </DialogFooter>
    </Dialog>;
};