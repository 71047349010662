import React, { useRef, useEffect, useState, useContext } from 'react';
import { Grid } from './Grid';
import { CommandBar } from '@talxis/react-components';
import { useViewSwitcher } from './ViewSelector/useViewSwitcher';
import { CommandBarButton, ThemeProvider, Shimmer, ShimmerElementType } from '@fluentui/react';
import { useQuickFind } from './QuickFind/useQuickFind';
import { QuickFindComponent } from './QuickFind/QuickFindComponent';
import { EditColumnsPanel } from './EditColumnsPanel/EditColumnsPanel';
import styles from './DatasetControl.module.css';
import { ThemeDefinition } from '@src/app/classes/definitions/ThemeDefinition';
import { QueryBuilderPanel } from './QueryBuilder/QueryBuilderPanel';
import { ViewLoading } from '@src/components/loadings/ViewLoading';
import { useControlState, useControlledNativeStateValues } from '../useControlState';
import { IFormContext } from '../native/Form/interfaces/IFormContext';
import { FormContext } from '../native/Form/Form';
import { MessageBar } from '@fluentui/react/lib/MessageBar';
import { IDatasetControlProps, IGridNativeStateValues, IInternalDatasetControlProps } from './interfaces';
import { RibbonController } from '@src/components/navigation/ribbon/RibbonController';
import { ICommandBarItemProps } from '@talxis/react-components';
import { DatasetControl } from './DatasetControl';
import { ViewManagerDialog } from './ViewManager/ViewManagerDialog/ViewManagerDialog';

export const DatasetControlRenderer: React.FC<IDatasetControlProps> = (props) => {
    const changeView = (queryId: string) => {
        currentViewIdRef.current = queryId;
        setShouldReloadGrid(true);
    };

    const currentViewIdRef = useRef<string>(props.bindings?.ViewId?.value);
    const [grid, setGrid] = useState<Grid>(null);
    const [shouldReloadGrid, setShouldReloadGrid] = useState<boolean>(false);
    const [viewSwitcherProps, initViewSwitcher, viewManagerDialogVisible, toggleViewManagerDialog] = useViewSwitcher(changeView);
    const [quickFindProps, initQuickFind, clearQuickFind] = useQuickFind();
    const [editColumnsPanelVisible, setEditColumnsPanelVisible] = useState<boolean>(false);
    const [queryBuilderPanelVisible, setQueryBuilderPanelVisible] = useState<boolean>(false);
    const [gridLoadFailed, setGridLoadFailed] = useState<boolean>(false);

    useEffect(() => {
        //initial load
        if (!grid) return;
        clearQuickFind();
        changeView(props.bindings?.ViewId.value);

    }, [props.bindings?.ViewId?.value]);
    useEffect(() => {
        setShouldReloadGrid(false);
        setGridLoadFailed(false);
    }, [shouldReloadGrid]);

    const onGridLoaded = (grid: Grid) => {
        initViewSwitcher(grid);
        initQuickFind(grid);
        setGrid(grid);
    };
    const isToolbarVisible = () => {
        if (props.bindings.IsViewPage) {
            if (props.bindings.EnableEditColumns?.value === 'true') {
                return true;
            }
        }
        if (grid.quickFind.isVisible()) {
            return true;
        }
        return false;
    };
    const getToolbarCommandBarItems = (): ICommandBarItemProps[] => {
        const items: ICommandBarItemProps[] = [];
        if (props.bindings.IsViewPage?.value) {
            if (props.bindings.EnableEditColumns.value === 'true') {
                items.push({
                    key: 'editColumns',
                    text: window.TALXIS.Portal.Translations.getLocalizedString('@pages/Control/View/EditColumns'),
                    iconProps: {
                        iconName: 'ColumnOptions'
                    },
                    onClick: () => setEditColumnsPanelVisible(true)
                });
            }
            items.push({
                key: 'editFilters',
                text: window.TALXIS.Portal.Translations.getLocalizedString('@pages/Control/View/EditFilters'),
                iconProps: {
                    iconName: 'Filter',
                },
                onClick: () => setQueryBuilderPanelVisible(true)
            });
        }
        if (quickFindProps) {
            items.push({
                key: 'quickFind',
                onRender: () => <QuickFindComponent {...quickFindProps} />
            });
        }
        return items;
    };
    return <section className={`TALXIS__portal__view-page ${styles.root}`}>
        {props.bindings.DisplayCommandBar?.value !== 'false' &&
            <RibbonController
                forceHidden={gridLoadFailed}
                ribbon={grid?.ribbon}
                showBackButton={props.bindings.IsViewPage?.value === 'true'}
                theme={props.bindings.IsViewPage?.value === 'true' ? ThemeDefinition.get().ribbon : ThemeDefinition.get().main} />
        }
        {grid &&
            <>
                {(grid.viewSelector.isVisible() || isToolbarVisible()) &&
                    <div style={!grid.isHomePageGrid ? {
                        '--view-header-left-padding': 0,
                        '--view-selector-text-size': '14px'
                    } as React.CSSProperties : undefined}
                        className={styles.viewHeader}>
                        {grid.viewSelector.isVisible() &&
                            <>
                                {viewSwitcherProps ?
                                    <div className={styles.viewSwitcher}>
                                        <CommandBarButton {...viewSwitcherProps} menuProps={
                                            {
                                                ...viewSwitcherProps.menuProps,
                                                calloutProps: {
                                                    className: styles.viewSwitcherCallout
                                                }
                                            }
                                        } />
                                    </div>
                                    :
                                    <Shimmer
                                        className={styles.sectionHeader}
                                        shimmerElements={[
                                            { type: ShimmerElementType.gap, width: 15, height: 30 },
                                            { type: ShimmerElementType.line, height: 10, width: 200 },
                                            { type: ShimmerElementType.gap, width: '100%', height: 30 }
                                        ]} />
                                }
                                {viewManagerDialogVisible &&
                                    <ViewManagerDialog
                                        grid={grid}
                                        onDismiss={() => toggleViewManagerDialog(false)}
                                    />
                                }
                            </>
                        }
                        {isToolbarVisible() &&
                            <CommandBar
                                className={styles.toolbarCommandBar}
                                overflowButtonProps={{
                                    menuProps: {
                                        items: [],
                                        calloutProps: {
                                            className: styles.toolbarCommandBar
                                        }
                                    }
                                }}
                                items={getToolbarCommandBarItems()} />
                        }
                    </div>
                }

            </>
        }
        {!shouldReloadGrid &&
            <InternalDatasetControl
                {...props}
                onGridLoaded={onGridLoaded}
                onGridLoadFailed={() => setGridLoadFailed(true)}
                switchingView={grid !== null}
                bindings={{
                    ...props.bindings,
                    ...(props.bindings.ViewId && {
                        ViewId: {
                            ...props.bindings.ViewId,
                            value: currentViewIdRef.current
                        }
                    })
                }}
            />
        }
        {editColumnsPanelVisible &&
            <EditColumnsPanel
                onDismiss={() => setEditColumnsPanelVisible(false)}
                grid={grid} />
        }
        {queryBuilderPanelVisible &&
            <QueryBuilderPanel
                grid={grid}
                onDismiss={() => setQueryBuilderPanelVisible(false)}
            />
        }

    </section>;
};

const InternalDatasetControl: React.FC<IInternalDatasetControlProps> = (props) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const gridRef = useRef<Grid>(null);
    const datasetControlRef = useRef<DatasetControl>(null);
    const controlStateRef = useControlState(`props.id_${props.bindings.ViewId?.value ?? ''}`);
    const formContextRef = useRef<IFormContext>(useContext(FormContext));

    const [nativeStateValuesRef, setDefaultStateValues] = useControlledNativeStateValues<IGridNativeStateValues>(controlStateRef, undefined, () => {
        nativeStateValuesRef.current.pageNumber = gridRef.current.paging.pageNumber;
        nativeStateValuesRef.current.pageSize = gridRef.current.paging.pageSize;
        nativeStateValuesRef.current.sorting = gridRef.current.sorting;
        nativeStateValuesRef.current.columns = gridRef.current.columns;
        nativeStateValuesRef.current.selectedRecordIds = gridRef.current.getSelectedRecordIds();
        nativeStateValuesRef.current.linkedEntities = gridRef.current.linking.getLinkedEntities();
        nativeStateValuesRef.current.dataSource = gridRef.current.getDataSource();
        nativeStateValuesRef.current.searchValue = gridRef.current.getSearchQuery();
        nativeStateValuesRef.current.userFilter = gridRef.current.filtering.getFilter();
    });
    const [loading, setIsLoading] = useState<boolean>(true);
    const mountedRef = useRef<boolean>(true);

    useEffect(() => {
        gridRef.current = new Grid(props.bindings.TargetEntityType.value);
        datasetControlRef.current = new DatasetControl(
            gridRef.current,
            containerRef.current,
            props,
            controlStateRef,
            {
                values: nativeStateValuesRef,
                setDefaultValues: (values) => {
                    setDefaultStateValues(values);
                }
            },
            formContextRef.current);
        (async () => {
            await datasetControlRef.current.init();
            const result = await gridRef.current.init(datasetControlRef.current);
            if (!mountedRef.current) {
                return;
            }
            if (result) {
                setIsLoading(false);
                props.onGridLoaded(gridRef.current);
                await datasetControlRef.current.mount();
            }
            else {
                props.onGridLoadFailed();
            }
            setIsLoading(false);
        })();
        return () => {
            mountedRef.current = false;
            datasetControlRef.current.unmount();
        };
    }, []);
    return (
        <div data-id={`dataSetRoot_${props.id}`}>
            <div className={props.name} ref={containerRef} />
            {loading &&
                <ThemeProvider className={styles.loadingBody} theme={props.bindings.IsViewPage?.value ? ThemeDefinition.get().ribbon : ThemeDefinition.get().main}>
                    <ViewLoading
                        //footer is always visible unless explicitly hidden via DataSetUIOptions
                        numOfRows={parseInt(props.bindings.RecordsPerPage?.value)}
                        footerPlaceHoder={true} />
                </ThemeProvider>
            }
            {!loading && gridRef.current?.isRelatedSubgridOnNewRecord &&
                <MessageBar>{window.TALXIS.Portal.Translations.getLocalizedString("@controls/DatasetControl/SaveNewForm")}</MessageBar>
            }
        </div>
    );
};